/* eslint-disable */
// @ts-nocheck
import { useState, useRef, useEffect } from 'react';
import { Map as ContextMap, APIProvider, AdvancedMarker, InfoWindow } from '@vis.gl/react-google-maps';
import { MdOutlineMyLocation } from 'react-icons/md';
import { toast } from 'react-toastify';
import { IPharmacy } from '@/types';
import axiosClient from '@/utils/axiosClient';

const Map = ({ pharmacy }: { pharmacy: IPharmacy }) => {
  const mapRef = useRef<google.maps.Map | null>(null);
  const markerRef = useRef<AdvancedMarkerElement | null>(null);

  const [center, setCenter] = useState<google.maps.LatLngLiteral>({
    lat: pharmacy.latitude ?? 37.7749,
    lng: pharmacy.longitude ?? -122.4194,
  });
  const [infowindowOpen, setInfowindowOpen] = useState(false);

  const changeCenter = (newCenter: google.maps.LatLngLiteral) => {
    console.log('Change center called with newCenter: ', newCenter);
    if (!newCenter) return;

    const payload = {
      latitude: newCenter.lat,
      longitude: newCenter.lng,
      pharmacy_id: pharmacy.id,
    };

    axiosClient.post(`/admin/geocode`, payload).then(() => {
      toast.success('Pharmacy location has been updated successfully');
    });
  };

  const handleDragEnd = (event: google.maps.MapMouseEvent) => {
    console.log('Marker dragged, event: ', event);
    if (!event.latLng) return;
    const newCenter = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    };
    changeCenter(newCenter);
  };

  useEffect(() => {
    if (markerRef.current) {
      markerRef.current.setPosition(center);
    }
  }, [center]);

  return (
    <>
      <APIProvider apiKey={import.meta.env.VITE_GOOGLE_MAPS_API_KEY}>
        <ContextMap
          defaultCenter={center}
          mapId={'bf51a910020fa25a'}
          className="rounded-lg h-[calc(100vh-450px)]"
          defaultZoom={14}
          gestureHandling={'greedy'}
          disableDefaultUI={false}
        >
          <AdvancedMarker
            ref={markerRef}
            position={center}
            title={'Pharmacy Location'}
            draggable
            onDragEnd={handleDragEnd}
            onClick={() => setInfowindowOpen(!infowindowOpen)}
          >
            <MdOutlineMyLocation className="w-8 h-8 -mb-4 text-brand-purple" />
          </AdvancedMarker>
          {infowindowOpen && markerRef.current && (
            <InfoWindow anchor={markerRef.current} maxWidth={225} onCloseClick={() => setInfowindowOpen(false)}>
              <div className="mb-2 text-sm font-semibold text-pretty">{pharmacy.name}</div>
              <div className="text-sm text-gray-600 text-pretty">{pharmacy.address1}</div>
              <div className="text-sm text-gray-600 text-pretty">
                {pharmacy.city}, {pharmacy.zip}
              </div>
            </InfoWindow>
          )}
        </ContextMap>
      </APIProvider>
    </>
  );
};

export default Map;
