import React, { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Badge } from '@/components/ui/badge';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import useUsers from '@/hooks/useUsers';
import { IUser } from '@/types';
import Loading from '../../Loading';
import AdminNav from '../AdminNav';

const formatDate = (dateString: string) => {
  if (!dateString) return 'N/A';
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });
};

const List: React.FC = () => {
  const [query, setQuery] = useState('');
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [customPerPage, setCustomPerPage] = useState('');
  const [isCustomPerPage, setIsCustomPerPage] = useState(false);
  const [sortBy, setSortBy] = useState<'email' | 'name' | 'phone' | 'created_at' | 'plan_end_at'>('created_at');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
  const { data, isLoading } = useUsers(query, page, perPage, sortBy, sortOrder);

  const navigate = useNavigate();

  const sortedUsers = useMemo(() => {
    if (!data?.users) return [];
    return [...data.users].sort((a, b) => {
      switch (sortBy) {
        case 'email':
          return sortOrder === 'asc'
            ? (a.email || '').localeCompare(b.email || '')
            : (b.email || '').localeCompare(a.email || '');
        case 'name': {
          const nameA = `${a.first_name || ''} ${a.last_name || ''}`.trim();
          const nameB = `${b.first_name || ''} ${b.last_name || ''}`.trim();
          return sortOrder === 'asc' ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
        }
        case 'phone':
          return sortOrder === 'asc'
            ? (a.phone || '').localeCompare(b.phone || '')
            : (b.phone || '').localeCompare(a.phone || '');
        case 'created_at': {
          const dateA = a.created_at ? new Date(a.created_at).getTime() : 0;
          const dateB = b.created_at ? new Date(b.created_at).getTime() : 0;
          return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
        }
        case 'plan_end_at': {
          if (a.plan === 'free' && b.plan !== 'free') return 1;
          if (a.plan !== 'free' && b.plan === 'free') return -1;
          if (a.plan === 'free' && b.plan === 'free') return 0;
          if (!a.plan_end_at) return 1;
          if (!b.plan_end_at) return -1;
          const endDateA = new Date(a.plan_end_at).getTime();
          const endDateB = new Date(b.plan_end_at).getTime();
          return sortOrder === 'asc' ? endDateA - endDateB : endDateB - endDateA;
        }
        default:
          return 0;
      }
    });
  }, [data?.users, sortBy, sortOrder]);

  const toggleSort = (column: 'email' | 'name' | 'phone' | 'created_at' | 'plan_end_at') => {
    if (sortBy === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(column);
      setSortOrder(column === 'email' || column === 'name' || column === 'phone' ? 'asc' : 'desc');
    }
    setPage(1);
  };

  const handleRowClick = (userId: string) => {
    navigate(`/admin/users/${userId}`);
  };

  const isSubscriptionActive = (endDate: string) => {
    return endDate && new Date(endDate) > new Date();
  };

  const SortIndicator: React.FC<{ column: typeof sortBy }> = ({ column }) => (
    <span className={`ml-1 ${column === sortBy ? 'text-purple-600' : 'text-gray-400'}`}>
      {sortBy === column ? (sortOrder === 'asc' ? '▲' : '▼') : '▼'}
    </span>
  );

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handlePerPageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    if (value === 'custom') {
      setIsCustomPerPage(true);
    } else {
      setPerPage(Number(value));
      setPage(1);
      setIsCustomPerPage(false);
    }
  };

  const handleCustomPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCustomPerPage(event.target.value);
  };

  const handleCustomPerPageSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const newPerPage = parseInt(customPerPage, 10);
    if (!isNaN(newPerPage) && newPerPage > 0) {
      setPerPage(newPerPage);
      setPage(1);
      setIsCustomPerPage(false);
    }
  };

  return (
    <>
      <AdminNav />
      <div className="container mx-auto px-4">
        <h1 className="text-2xl font-bold mb-4">User Search</h1>

        <input
          type="text"
          placeholder="Search by email..."
          className="w-full p-2 mb-4 border border-gray-300 rounded-md"
          onChange={(e) => {
            setQuery(e.target.value);
            setPage(1);
          }}
        />

        {isLoading && <Loading />}
        {!isLoading && data?.users && data.users.length > 0 ? (
          <>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead onClick={() => toggleSort('email')} className="cursor-pointer select-none">
                    Email <SortIndicator column="email" />
                  </TableHead>
                  <TableHead onClick={() => toggleSort('name')} className="cursor-pointer select-none">
                    Name <SortIndicator column="name" />
                  </TableHead>
                  <TableHead onClick={() => toggleSort('phone')} className="cursor-pointer select-none">
                    Phone <SortIndicator column="phone" />
                  </TableHead>
                  <TableHead onClick={() => toggleSort('created_at')} className="cursor-pointer select-none">
                    Created <SortIndicator column="created_at" />
                  </TableHead>
                  <TableHead onClick={() => toggleSort('plan_end_at')} className="cursor-pointer select-none">
                    Subscription <SortIndicator column="plan_end_at" />
                  </TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {sortedUsers.map((user: IUser) => (
                  <TableRow
                    key={user.id}
                    onClick={() => handleRowClick(user.id)}
                    className="cursor-pointer hover:bg-gray-100"
                  >
                    <TableCell>{user.email || ''}</TableCell>
                    <TableCell>{`${user.first_name || ''} ${user.last_name || ''}`.trim()}</TableCell>
                    <TableCell>{user.phone || ''}</TableCell>
                    <TableCell>{formatDate(user.created_at)}</TableCell>
                    <TableCell>
                      <div className="flex items-center">
                        {user.plan !== 'free' && user.plan_end_at && (
                          <Badge variant={isSubscriptionActive(user.plan_end_at) ? 'in_stock' : 'out_of_stock'}>
                            {isSubscriptionActive(user.plan_end_at) ? 'Active' : 'Inactive'}
                          </Badge>
                        )}
                        <span className="ml-2">"{user.plan || 'No plan'}"</span>
                        {user.plan !== 'free' && user.plan_end_at && (
                          <span className="ml-2">- Expires {formatDate(user.plan_end_at)}</span>
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <div className="mt-4 flex justify-between items-center">
              <span>
                Showing {((data.current_page || 1) - 1) * perPage + 1} to{' '}
                {Math.min((data.current_page || 1) * perPage, data.total_count || 0)} of {data.total_count || 0} users
              </span>
              <div className="ml-auto flex items-center space-x-4">
                <div>
                  <label htmlFor="perPage" className="mr-2">
                    Items per page:
                  </label>
                  {isCustomPerPage ? (
                    <form onSubmit={handleCustomPerPageSubmit} className="flex">
                      <input
                        type="number"
                        value={customPerPage}
                        onChange={handleCustomPerPageChange}
                        className="w-20 p-1 border border-gray-300 rounded-md"
                        min="1"
                      />
                      <button type="submit" className="ml-2 px-2 py-1 bg-blue-500 text-white rounded">
                        Set
                      </button>
                    </form>
                  ) : (
                    <select
                      id="perPage"
                      value={perPage}
                      onChange={handlePerPageChange}
                      className="border border-gray-300 rounded-md p-1"
                    >
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="custom">Custom</option>
                    </select>
                  )}
                </div>
                <div>
                  <button
                    onClick={() => handlePageChange((data.current_page || 1) - 1)}
                    disabled={(data.current_page || 1) === 1}
                    className="px-3 py-1 border rounded mr-2"
                  >
                    Previous
                  </button>
                  <button
                    onClick={() => handlePageChange((data.current_page || 1) + 1)}
                    disabled={(data.current_page || 1) === (data.total_pages || 1)}
                    className="px-3 py-1 border rounded"
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="text-center text-gray-500">
            {isLoading ? 'Loading...' : `No users found ${query ? `for "${query}"` : ''}`}
          </div>
        )}
      </div>
    </>
  );
};

export default List;
