import useRequest from '@/hooks/useRequest';
import { IRequest, ITask } from '@/types';
import Medication from './Medication';

const Medications = ({ task }: { task: ITask }) => {
  const request = useRequest(task.request_id, true);

  const allTasksForThisPharmacy = request?.data?.tasks.filter((t) => t.pharmacy_id === task.pharmacy_id) || [];

  if (request?.isLoading || !request?.data?.id) {
    return null;
  }

  return (
    <ul className="w-full mt-8 border border-gray-300 divide-y rounded-lg divide-y-gray-300">
      {allTasksForThisPharmacy.map((task, index) => (
        <Medication
          key={index}
          index={index}
          task={task}
          medication={task.medication}
          request={request?.data as IRequest}
        />
      ))}
    </ul>
  );
};

export default Medications;
