import { Bot, User2Icon } from 'lucide-react';
import Time from '@/components/Time';
import { Badge } from '@/components/ui/badge';
import { ICall } from '@/types';

interface CallProps {
  call: ICall;
}

const Call = ({ call }: CallProps) => {
  return (
    <>
      <div className="flex flex-col w-full gap-2 p-2 bg-white">
        <div className="flex justify-between w-full text-sm">
          <div className="flex flex-row items-center gap-3">
            {call.ai_initiated ? (
              <div className="p-1 border border-pink-700 rounded-full">
                <Bot className="text-pink-600" />
              </div>
            ) : (
              <div className="p-1 border border-green-700 rounded-full">
                <User2Icon className="text-green-600" />
              </div>
            )}
          </div>

          <div className="flex flex-col justify-end flex-1 gap-1 text-right ">
            <div className="text-xs">
              <Badge color="blue" className="h-5 text-xs">
                {call.status}
              </Badge>
            </div>
            <div className="text-xs">
              <Time>{call.created_at}</Time>
            </div>
          </div>

          <div className="flex flex-col justify-end gap-1 text-right"></div>
        </div>
      </div>
    </>
  );
};

export default Call;
