import { ICall, IMedication, ITask, IPharmacy } from '@/types';
import { getUrl } from '@/utils/api';

interface ICallWithDetails extends Omit<ICall, 'medication' | 'task'> {
  medication: IMedication;
  task: ITask & { stock_status: string; request_id: string };
  pharmacy: IPharmacy;
}

function useCallHistory(pharmacy_id: string) {
  const query = getUrl(`admin/pharmacies/${pharmacy_id}/call_history`);
  return {
    ...query,
    data: query.data as ICallWithDetails[],
  };
}

export default useCallHistory;
