const drugs = [
  {
    category: 'Pain',
    name: 'Morphine Sulfate',
    generic: 'Morphine Sulfate',
    variants: [
      {
        name: 'Oral Solution',
        short: 'Solution',
        dosages: ['10 mg/5 mL', '20 mg/5 mL', '30 mg/5 mL'],
        dosageForm: 'Oral Solution',
      },
      {
        name: 'Tablet',
        short: 'Tablet',
        dosages: ['15 mg', '30 mg'],
        dosageForm: 'Tablet',
      },
      {
        name: 'Extended-Release',
        short: 'ER',
        dosages: ['15 mg', '30 mg', '60 mg', '100 mg', '200 mg'],
        dosageForm: 'Tablet',
      },
    ],
  },
  {
    category: 'ADHD',
    name: 'Adderall',
    generic: 'Amphetamine Salts',
    variants: [
      {
        name: 'Immediate Release',
        short: 'IR',
        dosages: ['5 mg', '7.5 mg', '10 mg', '12.5 mg', '15 mg', '20 mg', '30 mg'],
        dosageForm: 'Tablet',
      },
      {
        name: 'Extended Release',
        short: 'ER',
        dosages: ['5 mg', '10 mg', '15 mg', '20 mg', '25 mg', '30 mg'],
        dosageForm: 'Capsule',
      },
    ],
  },
  {
    category: 'ADHD',
    name: 'Adzenys',
    generic: 'Amphetamine',
    variants: [
      {
        name: 'Extended Release',
        short: 'XR-ODT',
        dosages: ['3.1 mg', '6.3 mg', '9.4 mg', '12.5 mg', '15.7 mg', '18.8 mg'],
        dosageForm: 'Tablet',
      },
    ],
  },
  {
    category: 'ADHD',
    name: 'Aptensio',
    generic: 'Methylphenidate',
    variants: [
      {
        name: 'Extended Release',
        short: 'XR',
        dosages: ['10 mg', '15 mg', '20 mg', '30 mg', '40 mg', '50 mg', '60 mg', '80 mg'],
        dosageForm: 'Capsule',
      },
    ],
  },
  {
    category: 'ADHD',
    name: 'Azstarys',
    generic: 'Dexmethylphenidate',
    variants: [
      {
        name: 'Extended Release',
        short: 'ER',
        dosages: ['26.1 mg / 5.2 mg', '39.2 mg / 7.8 mg', '52.3 mg / 10.4 mg'],
        dosageForm: 'Capsule',
      },
    ],
  },
  {
    category: 'ADHD',
    name: 'Concerta',
    generic: 'Methylphenidate',
    variants: [
      {
        name: 'Extended Release',
        short: 'ER',
        dosages: ['18 mg', '27 mg', '36 mg', '54 mg', '72 mg'],
        dosageForm: 'Tablet',
      },
    ],
  },
  {
    category: 'ADHD',
    name: 'Cotempla XR-ODT',
    generic: 'Methylphenidate',
    variants: [
      {
        name: 'Extended Release',
        short: 'XR',
        dosages: ['17.3 mg', '25.9 mg', '34.6 mg'],
        dosageForm: 'Tablet',
      },
    ],
  },
  {
    category: 'ADHD',
    name: 'Daytrana',
    generic: 'Methylphenidate',
    variants: [
      {
        name: 'Transdermal Patch',
        short: 'Patch',
        dosages: ['10 mg', '15 mg', '20 mg', '30 mg'],
        dosageForm: 'Patch',
      },
    ],
  },
  {
    category: 'ADHD',
    name: 'Desoxyn',
    generic: 'Methamphetamine',
    variants: [
      {
        name: 'Immediate Release',
        short: 'IR',
        dosages: ['5 mg'],
        dosageForm: 'Tablet',
      },
    ],
  },
  {
    category: 'ADHD',
    name: 'Desoxyn',
    generic: 'Methamphetamine',
    variants: [
      {
        name: 'Immediate Release',
        short: 'IR',
        dosages: ['5 mg'],
        dosageForm: 'Tablet',
      },
    ],
  },
  {
    category: 'ADHD',
    name: 'Dexedrine',
    generic: 'Dextroamphetamine',
    variants: [
      {
        name: 'Immediate Release',
        short: 'IR',
        dosages: ['5 mg', '10 mg'],
        dosageForm: 'Tablet',
      },
      {
        name: 'Spansule',
        short: 'ER',
        dosages: ['5 mg', '10 mg', '15 mg'],
        dosageForm: 'Capsule',
      },
    ],
  },
  {
    category: 'ADHD',
    name: 'Dyanavel',
    generic: 'Amphetamine',
    variants: [
      {
        name: 'Extended Release',
        short: 'XR',
        dosages: ['2.5 mg', '3.75 mg', '5 mg', '6.25 mg', '7.5 mg', '10 mg', '12.5 mg', '15 mg', '20 mg'],
        dosageForm: 'Suspension',
      },
    ],
  },
  {
    category: 'ADHD',
    name: 'Evekeo',
    generic: 'Amphetamine',
    variants: [
      {
        name: 'Immediate Release',
        short: 'IR',
        dosages: ['5 mg', '10 mg'],
        dosageForm: 'Tablet',
      },
    ],
  },
  {
    category: 'ADHD',
    name: 'Focalin',
    generic: 'Dexmethylphenidate',
    variants: [
      {
        name: 'Immediate Release',
        short: 'IR',
        dosages: ['2.5 mg', '5 mg', '10 mg'],
        dosageForm: 'Tablet',
      },
      {
        name: 'Extended Release',
        short: 'XR',
        dosages: ['5 mg', '10 mg', '15 mg', '20 mg', '25 mg', '30 mg', '35 mg', '40 mg'],
        dosageForm: 'Capsule',
      },
    ],
  },
  {
    category: 'ADHD',
    name: 'Intuniv',
    generic: 'Guanfacine',
    variants: [
      {
        name: 'Extended Release',
        short: 'ER',
        dosages: ['1 mg', '2 mg', '3 mg', '4 mg'],
        dosageForm: 'Tablet',
      },
    ],
  },
  {
    category: 'ADHD',
    name: 'Metadate CD',
    generic: 'Methylphenidate',
    variants: [
      {
        name: 'Extended Release',
        short: 'ER',
        dosages: ['10 mg', '20 mg', '30 mg', '40 mg', '50 mg', '60 mg'],
        dosageForm: 'Capsule',
      },
    ],
  },
  {
    category: 'ADHD',
    name: 'Mydayis',
    generic: 'Dextroamphetamine',
    variants: [
      {
        name: 'Extended Release',
        short: 'ER',
        dosages: ['12.5 mg', '25 mg', '37.5 mg', '50 mg'],
        dosageForm: 'Capsule',
      },
    ],
  },
  {
    category: 'ADHD',
    name: 'Quillichew ER',
    generic: 'Methylphenidate',
    variants: [
      {
        name: 'Chewable',
        short: 'ER',
        dosages: ['20 mg', '30 mg', '40 mg'],
        dosageForm: 'Tablet',
      },
    ],
  },
  {
    category: 'ADHD',
    name: 'Quillivant XR',
    generic: 'Methylphenidate',
    variants: [
      {
        name: 'Extended Release - 25 mg/5 mL',
        short: 'XR',
        dosages: ['60 mL', '120 mL', '150 mL', '180 mL'],
        dosageForm: 'Suspension',
      },
    ],
  },
  {
    category: 'ADHD',
    name: 'Ritalin',
    generic: 'Methylphenidate',
    variants: [
      {
        name: 'Immediate Release',
        short: 'IR',
        dosages: ['5 mg', '10 mg', '20 mg'],
        dosageForm: 'IR Tablet',
      },
      {
        name: 'Sustained Release',
        short: 'SR',
        dosages: ['20 mg'],
        dosageForm: 'SR Tablet',
      },
      {
        name: 'Long-Acting',
        short: 'LA',
        dosages: ['10 mg', '20 mg', '30 mg'],
        dosageForm: 'LA Capsule',
      },
      {
        name: 'Extended Release',
        short: 'CD',
        dosages: ['10 mg', '20 mg', '30 mg', '40 mg', '50 mg', '60 mg'],
        dosageForm: 'CD Capsule',
      },
    ],
  },
  {
    category: 'ADHD',
    name: 'Strattera',
    generic: 'Atomoxetine',
    variants: [
      {
        name: 'Delayed Release',
        short: 'DR',
        dosages: ['10 mg', '18 mg', '25 mg', '40 mg', '60 mg', '80 mg', '100 mg'],
        dosageForm: 'Capsule',
      },
    ],
  },
  {
    category: 'ADHD',
    name: 'Vyvanse',
    generic: 'Lisdexamfetamine',
    variants: [
      {
        name: 'Capsule',
        short: 'Capsule',
        dosages: ['10 mg', '20 mg', '30 mg', '40 mg', '50 mg', '60 mg', '70 mg'],
        dosageForm: 'Capsule',
      },
      {
        name: 'Chewable Tablets',
        short: 'Chewable',
        dosages: ['10 mg', '20 mg', '30 mg', '40 mg', '50 mg', '60 mg', '70 mg'],
        dosageForm: 'Chewable Tablets',
      },
    ],
  },

  {
    category: 'ADHD',
    name: 'Zenzedi',
    generic: 'Dextroamphetamine',
    variants: [
      {
        name: 'Immediate Release',
        short: 'IR',
        dosages: ['2.5 mg', '5 mg', '7.5 mg', '10 mg', '15 mg', '20 mg', '30 mg'],
        dosageForm: 'Tablet',
      },
    ],
  },

  {
    category: 'GLP-1',
    name: 'Wegovy',
    generic: 'Semaglutide',
    variants: [
      {
        name: 'Subcutaneous Injection',
        short: 'Injection',
        dosages: ['0.25 mg', '0.5 mg', '1 mg', '1.7 mg', '2.4 mg'],
        dosageForm: 'Pre-filled Pens (Box of 4)',
      },
    ],
  },
  {
    category: 'GLP-1',
    name: 'Ozempic',
    generic: 'Semaglutide',
    variants: [
      {
        name: 'Subcutaneous Injection',
        short: 'Injection',
        dosages: ['0.25 mg', '0.5 mg', '1 mg'],
        dosageForm: 'Pre-filled Pens (Box of 4)',
      },
    ],
  },
  {
    category: 'GLP-1',
    name: 'Rybelsus',
    generic: 'Semaglutide',
    variants: [
      {
        name: 'Oral',
        short: 'Oral',
        dosages: ['3 mg', '7 mg', '14 mg'],
        dosageForm: 'Tablet',
      },
    ],
  },
  {
    category: 'GLP-1',
    name: 'Mounjaro',
    generic: 'Tirzepatide',
    variants: [
      {
        name: 'Subcutaneous Injection',
        short: 'Injection',
        dosages: ['2.5 mg', '5 mg', '7.5 mg', '10 mg', '12.5 mg', '15 mg', '30 mg'],
        rxcui: ['2562822', '2562826'],
        dosageForm: 'Pre-filled Pens (Box of 4)',
      },
    ],
  },
  {
    category: 'GLP-1',
    name: 'Trulicity',
    generic: 'Dulaglutide',
    variants: [
      {
        name: 'Subcutaneous Injection',
        short: 'Injection',
        dosages: ['0.75 mg', '1.5 mg', '3 mg', '4.5 mg'],
        dosageForm: 'Pre-filled Pens (Box of 4)',
      },
    ],
  },
  {
    category: 'Weight Loss',
    name: 'Victoza',
    generic: 'Liraglutide',
    variants: [
      {
        name: 'Subcutaneous Injection',
        short: 'Injection',
        dosages: ['0.6 mg', '1.2 mg', '1.8 mg'],
        dosageForm: 'Pre-filled Pens (Box of 4)',
      },
    ],
  },
  {
    category: 'GLP-1',
    name: 'Zepbound',
    generic: 'Tirzepatide',
    variants: [
      {
        name: 'Subcutaneous Injection',
        short: 'Injection',
        dosages: ['2.5 mg', '5 mg', '7.5 mg', '10 mg', '12.5 mg', '15 mg'],
        rxcui: ['2562822', '2562826'],
        dosageForm: 'Pre-filled Pens (Box of 4)',
      },
    ],
  },
  {
    category: 'GLP-1',
    name: 'Saxenda',
    generic: 'Liraglutide',
    variants: [
      {
        name: 'Subcutaneous Injection',
        short: 'Injection',
        dosages: ['0.6 mg', '1.2 mg', '1.8 mg', '2.4 mg', '3 mg'],
        dosageForm: 'Pre-filled Pen (Box of 1)',
      },
    ],
  },
  {
    category: 'Seizure/Epilepsy',
    name: 'Felbatol',
    generic: 'Felbamate',
    variants: [
      {
        name: 'Tablet',
        short: 'Tablet',
        dosages: ['400 mg', '600 mg'],
        dosageForm: 'Tablet',
      },
      {
        name: 'Oral Suspension',
        short: 'Oral',
        dosages: ['600 mg/5 ml'],
        dosageForm: 'Oral Suspension',
      },
    ],
  },
];

export default drugs;
