//import { Link } from 'react-router-dom';

const AdminNav = () => {
  return (
    <div className="flex gap-4 mt-4 text-sm">
      {/* <Link to="/admin/requests">Requests</Link> */}
      {/* <Link to="/admin/users">Users</Link> */}
    </div>
  );
};

export default AdminNav;
