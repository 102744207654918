import { useState, useMemo } from 'react';
import { Search, AlertTriangle, CheckCircle, XCircle, Phone } from 'lucide-react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip as RechartsTooltip,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import useAnalytics from '@/hooks/useAnalytics';
import Time from '../Time';

const StatusIcon = ({ status }: any) => {
  switch (status) {
    case 'shortage':
    case 'out_of_stock':
      return <XCircle className="w-5 h-5 text-red-500" />;
    case 'low_stock':
      return <AlertTriangle className="w-5 h-5 text-yellow-500" />;
    case 'available':
    case 'in_stock':
      return <CheckCircle className="w-5 h-5 text-green-500" />;
    default:
      return <AlertTriangle className="w-5 h-5 text-gray-500" />;
  }
};

const MedicationCard = ({ medication }: any) => (
  <Card className="mb-6">
    <CardHeader className="bg-muted">
      <CardTitle className="flex items-center space-x-2">
        <span>{medication.name}</span>
      </CardTitle>
    </CardHeader>
    <CardContent className="pt-4">
      {medication.variants.map((variant: any, variantIndex: any) => {
        const sortedDosages = variant.dosages.sort((a: any, b: any) => a.reports - b.reports);
        return (
          <div key={variantIndex} className="mb-4">
            <h3 className="mb-2 font-semibold">{variant.name}</h3>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Dosages</TableHead>
                  <TableHead>Stock</TableHead>
                  <TableHead>Calls</TableHead>
                  <TableHead>Last Contacted</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {sortedDosages.map((dosage: any, dosageIndex: any) => (
                  <TableRow key={dosageIndex}>
                    <TableCell>{dosage.dose}</TableCell>
                    <TableCell>
                      <div className="flex items-center space-x-2">
                        <StatusIcon status={dosage.status} />
                        <span>{dosage.status === 'shortage' ? 'Shortage' : 'Available'}</span>
                      </div>
                    </TableCell>
                    <TableCell>
                      <Badge variant="secondary" className="gap-2">
                        <Phone className="w-4 h-4 mr-1" />
                        {dosage.reports}
                      </Badge>
                    </TableCell>
                    <TableCell>
                      <Time>{dosage.lastUpdated}</Time>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        );
      })}
    </CardContent>
  </Card>
);

const AvailabilityTool = () => {
  const data = useAnalytics();
  const [selectedType, setSelectedType] = useState('All');
  const [searchTerm, setSearchTerm] = useState('');

  const filteredMedications = useMemo(() => {
    return data?.data?.filter(
      (med: any) =>
        (selectedType === 'All' || med.type === selectedType) &&
        (med.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          med.type.toLowerCase().includes(searchTerm.toLowerCase()) ||
          (med.manufacturer && med.manufacturer.toLowerCase().includes(searchTerm.toLowerCase()))),
    );
  }, [data, selectedType, searchTerm]);

  // Aggregate data for Bar Chart (Total Reports per Medication)
  const barChartData = useMemo(() => {
    return filteredMedications?.map((med: any) => {
      const totalReports = med.variants.reduce((acc: number, variant: any) => {
        return acc + variant.dosages.reduce((sum: number, dosage: any) => sum + dosage.reports, 0);
      }, 0);
      return { name: med.name, reports: totalReports };
    });
  }, [filteredMedications]);

  return (
    <div className="container p-4 mx-auto">
      <h1 className="mb-2 text-3xl font-bold">Medication Shortage and Pharmacy Stock Dashboard</h1>
      <p className="mb-6 text-muted-foreground">
        Detailed view of medication shortages, pharmacy stock levels, and area health for prescribers.
      </p>

      {/* Charts Section */}
      <div className="mb-8">
        <div className="grid grid-cols-1 ">
          {/* Bar Chart */}
          <Card>
            <CardHeader>
              <CardTitle>Total Shortage Reports per Medication</CardTitle>
            </CardHeader>
            <CardContent>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={barChartData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" tick={{ fontSize: 12 }} />
                  <YAxis />
                  <RechartsTooltip />
                  <Legend />
                  <Bar dataKey="reports" fill="#8884d8" name="Reports" />
                </BarChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </div>
      </div>

      {/* Filters Section */}
      <div className="flex flex-col gap-4 mb-6 md:flex-row">
        <Select onValueChange={setSelectedType} defaultValue="All">
          <SelectTrigger className="w-full md:w-[180px]">
            <SelectValue placeholder="Select medication type" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="All">All Types</SelectItem>
            <SelectItem value="GLP-1">GLP-1</SelectItem>
            <SelectItem value="ADHD">ADHD</SelectItem>
            {/* Add more types as needed */}
          </SelectContent>
        </Select>
        <div className="flex w-full md:w-auto">
          <Input
            type="text"
            placeholder="Search medications..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="mr-2"
          />
          <Button type="submit">
            <Search className="w-4 h-4" />
          </Button>
        </div>
      </div>

      {/* Tabs Section */}
      <Tabs defaultValue="medications" className="mb-6">
        <TabsList>
          <TabsTrigger value="medications">Medications</TabsTrigger>
          <TabsTrigger value="pharmacies">Pharmacies</TabsTrigger>
        </TabsList>
        <TabsContent value="medications">
          <div className="grid grid-cols-1 gap-6">
            {filteredMedications && filteredMedications.length > 0 ? (
              <>
                {filteredMedications.map((medication, index) => (
                  <MedicationCard key={index} medication={medication} />
                ))}
              </>
            ) : (
              <>No medications found.</>
            )}
          </div>
        </TabsContent>
        <TabsContent value="pharmacies">
          <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
            {/* {pharmacies.map((pharmacy, index) => (
              <PharmacyCard key={index} pharmacy={pharmacy} selectedMedication={selectedMedication} />
            ))} */}
            <p>Pharmacies data will be displayed here.</p>
          </div>
        </TabsContent>
      </Tabs>
    </div>
  );
};
export default AvailabilityTool;
