import { useEffect, useState } from 'react';
import { MapPin } from 'lucide-react';
import { Link } from 'react-router-dom';
import ClickToCopy from '@/components/ClickToCopy';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Switch } from '@/components/ui/switch';
import { ITask } from '@/types';
import axiosClient from '@/utils/axiosClient.ts';
import { formatPhoneNumber } from '@/utils/helpers';

interface PharmacyProps {
  task: ITask;
}

export default function Pharmacy({ task }: PharmacyProps) {
  const [doNotAiCall, setDoNotAiCall] = useState(task.pharmacy.do_not_ai_call);

  useEffect(() => {
    if (task.pharmacy) {
      setDoNotAiCall(task.pharmacy.do_not_ai_call);
    }
  }, [task.pharmacy.do_not_ai_call]);

  const formatGoogleMapsLink = () => {
    const address = `${task.pharmacy.address1} ${task.pharmacy.city} ${task.pharmacy.state} ${task.pharmacy.zip}`;
    const encodedAddress = encodeURIComponent(address);
    return `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`;
  };

  const mapUrl = formatGoogleMapsLink();

  const handleToggleAiCall = async () => {
    try {
      const response = await axiosClient.patch(`/admin/pharmacies/${task.pharmacy.id}/update_ai_call_preference`, {
        do_not_ai_call: !doNotAiCall,
      });
      if (response.data.success) {
        setDoNotAiCall(response.data.do_not_ai_call);
      }
    } catch (error) {
      console.error('Error updating AI call preference:', error);
    }
  };

  return (
    <div className="flex flex-col w-full">
      <div className="flex justify-between gap-8">
        <div className="flex flex-col flex-1">
          <div className="flex flex-col justify-start items-start space-y-1">
            <ClickToCopy text={task.pharmacy.name}>
              <div className="text-xl font-bold leading-6 text-left text-gray-800 text-pretty">
                {task.pharmacy.name}
              </div>
            </ClickToCopy>
            <div className="text-sm text-gray-600">{task.pharmacy.address1}</div>
            <div className="text-sm text-gray-600">
              {task.pharmacy.city} {task.pharmacy.state} {task.pharmacy.zip}
            </div>
            <div className="text-sm text-gray-600">
              <ClickToCopy text={formatPhoneNumber(task.pharmacy.phone)}>
                {formatPhoneNumber(task.pharmacy.phone)}
              </ClickToCopy>
            </div>
          </div>
          <div className="flex items-center mt-3 mb-3">
            <div className="flex items-center">
              <Switch id="ai-call-switch" checked={doNotAiCall} onCheckedChange={handleToggleAiCall} />
            </div>
            <label
              htmlFor="ai-call-switch"
              className={`ml-2 text-sm font-medium ${
                doNotAiCall ? 'text-red-500' : 'text-green-600'
              } flex items-center`}
            >
              {doNotAiCall ? "AI can't call this pharmacy" : 'AI can call this pharmacy'}
            </label>
          </div>
        </div>

        <div className="flex flex-col justify-end flex-1 gap-3">
          <div className="flex items-center justify-end gap-1">
            <Button variant="outline" asChild>
              <Link to={mapUrl} target="_blank">
                <MapPin className="w-8" />
              </Link>
            </Button>
          </div>
          <div className="flex flex-col items-end justify-end w-full gap-2">
            <ClickToCopy text={task.pharmacy.id}>
              <Badge>Copy PharmID</Badge>
            </ClickToCopy>
            <Link to={`/admin/requests/${task.request_id}/${task.id}`}>
              <Badge variant="pending">Go To Request</Badge>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
