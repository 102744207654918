import { useState, useEffect } from 'react';
import { useModal } from '@ebay/nice-modal-react';
import { ArrowLeft } from 'lucide-react';
import { Link } from 'react-router-dom';
import { ExpeditedIndicator } from '@/components/Requests/DataTable/ExpeditedIndicator';
import Section from '@/components/Section';
import { Button } from '@/components/ui/button';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import useManualCalls from '@/hooks/useManualCalls';
import useTickets from '@/hooks/useTickets';
import { ITask, ITicket } from '@/types';

const REFETCH_INTERVAL = 30000; // 30 seconds

const Tickets = () => {
  const [polling, setPolling] = useState(true);
  const [countdown, setCountdown] = useState(REFETCH_INTERVAL / 1000);

  const taskModal = useModal('task-modal');
  const scheduledTickets = useTickets('scheduled', {
    refetchInterval: polling ? REFETCH_INTERVAL : false,
  });
  const manualCalls = useManualCalls({
    refetchInterval: polling ? REFETCH_INTERVAL : false,
  });

  const sortedCalls = manualCalls?.data?.sort(
    (a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
  ) as ITask[];

  const calculateCompletionRatio = (ticket: ITicket) => {
    if (!ticket.tasks || ticket.tasks.length === 0) return '0/0';
    const completedTasks = ticket.tasks.filter((task) => task.status === 'completed').length;
    return `${completedTasks}/${ticket.tasks.length}`;
  };

  useEffect(() => {
    let interval: any;
    if (polling) {
      interval = setInterval(() => {
        scheduledTickets.refetch();
        manualCalls.refetch();
        setCountdown(REFETCH_INTERVAL / 1000);
      }, REFETCH_INTERVAL);

      const countdownInterval = setInterval(() => {
        setCountdown((prevCountdown) => (prevCountdown > 0 ? prevCountdown - 1 : REFETCH_INTERVAL / 1000));
      }, 1000);

      return () => {
        clearInterval(interval);
        clearInterval(countdownInterval);
      };
    }
  }, [polling, manualCalls, scheduledTickets]);

  const calculateScheduledTickets = (tickets: ITicket[]) => {
    return tickets?.length || 0;
  };

  const calculateManualCalls = (calls: ITask[]) => {
    return calls?.length || 0;
  };

  return (
    <div className="">
      <div className="flex justify-between w-full">
        <div className="flex items-center justify-start gap-1 mt-4 mb-6 sm:mt-2 ">
          <div className="mr-2 leading-snug text-gray-600 hover:text-violet-600">
            <Link to="/admin/tickets">
              <ArrowLeft />
            </Link>
          </div>
          <div className="font-medium leading-snug text-gray-400 hover:text-violet-600">
            <Link to="/admin/tickets">Work Items</Link>
          </div>
          <div className="px-2 font-medium leading-snug text-gray-400">/</div>
          <div className="font-medium leading-snug text-violet-600">Open Tickets</div>
        </div>
        <div className="flex items-center justify-end">
          <Button onClick={() => setPolling(!polling)}>{polling ? `Polling (${countdown}s)` : 'Start Polling'}</Button>
        </div>
      </div>

      <div className="flex flex-col w-full gap-8 lg:flex-row">
        <div className="">
          <Section title={`Tickets (${calculateScheduledTickets(scheduledTickets?.data)})`}>
            <Table className="text-xs">
              <TableHeader>
                <TableRow>
                  <TableHead>Scheduled</TableHead>
                  <TableHead>Request</TableHead>
                  <TableHead>Email</TableHead>
                  <TableHead>Location</TableHead>
                  <TableHead>Completed</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {scheduledTickets?.data?.map((ticket: ITicket) => (
                  <TableRow key={ticket.id}>
                    <TableCell>
                      <div className="flex items-center space-x-2">
                        <ExpeditedIndicator processingTime={ticket.request?.processing_time} />
                        <span>{ticket.scheduled_at}</span>
                      </div>
                    </TableCell>
                    <TableCell>
                      <Link
                        to={`/admin/requests/${ticket.request_id}`}
                        target="_blank"
                        className="underline underline-offset-2 decoration-gray-300 hover:decoration-gray-600"
                      >
                        {ticket.request_id}
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Link
                        to={`/admin/users/${ticket.user.id}`}
                        target="_blank"
                        className="underline underline-offset-2 decoration-gray-300 hover:decoration-gray-600"
                      >
                        {ticket.user.email}
                      </Link>
                    </TableCell>
                    <TableCell>{ticket.location}</TableCell>
                    <TableCell>{calculateCompletionRatio(ticket)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Section>
        </div>

        <div className="">
          <Section title={`Manual Calls (${calculateManualCalls(sortedCalls)})`}>
            <Table className="text-xs">
              <TableHeader>
                <TableRow>
                  <TableHead className="w-1/5">Pharmacy</TableHead>
                  <TableHead className="w-1/5">Medication</TableHead>
                  <TableHead className="w-1/5">Dosage</TableHead>
                  <TableHead className="w-1/5">Quantity</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {sortedCalls?.map((task: ITask) => (
                  <TableRow
                    key={task.id}
                    onClick={() => {
                      taskModal.show({ taskId: task.id, requestId: task.request_ticket?.request?.id });

                      window.history.pushState(
                        null,
                        '',
                        `/admin/requests/${task.request_ticket?.request?.id}/${task.id}/${task.medication.id}`,
                      );
                    }}
                    className="cursor-pointer"
                  >
                    <TableCell className="whitespace-normal">
                      <div className="flex items-center gap-2 space-x-2">
                        <ExpeditedIndicator processingTime={task.request_ticket?.request?.processing_time} />
                        <span>{task.pharmacy?.name || 'N/A'}</span>
                      </div>
                    </TableCell>
                    <TableCell className="whitespace-normal">{task.medication?.medication || 'N/A'}</TableCell>
                    <TableCell className="whitespace-normal">{task.medication?.dosage || 'N/A'}</TableCell>
                    <TableCell className="whitespace-normal">{task.medication?.quantity || 'N/A'}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Section>
        </div>
      </div>
    </div>
  );
};

export default Tickets;
