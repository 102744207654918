import { useState, useRef } from 'react';
import { AdvancedMarker, APIProvider, InfoWindow, Map, useAdvancedMarkerRef } from '@vis.gl/react-google-maps';
import { MdOutlineMyLocation } from 'react-icons/md';
import { useQueryClient } from 'react-query';
import { useDebouncedCallback } from 'use-debounce';
import { Circle } from '@/components/Location/Circle';
import PharmacyIcon from '@/components/PharmacyIcon';
import { Slider } from '@/components/ui/slider';
import usePharmacyFinder from '@/hooks/usePharmacyFinder.ts';
import { IPharmacy, IRequest } from '@/types.ts';
import axiosClient from '@/utils/axiosClient.ts';
import { Badge } from '../ui/badge';

interface MarkerWithInfowindowProps {
  pharmacy: IPharmacy;
}

const MarkerWithInfowindow = ({ pharmacy }: MarkerWithInfowindowProps) => {
  const [infowindowOpen, setInfowindowOpen] = useState(false);
  const [markerRef, marker] = useAdvancedMarkerRef();

  return (
    <>
      <AdvancedMarker
        ref={markerRef}
        onClick={() => setInfowindowOpen(!infowindowOpen)}
        position={{
          lat: parseFloat(String(pharmacy.latitude)),
          lng: parseFloat(String(pharmacy.longitude)),
        }}
        title={pharmacy.name}
      >
        <PharmacyIcon pharmacy={pharmacy} />
      </AdvancedMarker>
      {infowindowOpen && (
        <InfoWindow anchor={marker} maxWidth={225} onCloseClick={() => setInfowindowOpen(true)}>
          <div className="mb-2 text-sm font-semibold text-pretty">{pharmacy.name}</div>
          <div className="text-sm text-gray-600 text-pretty">{pharmacy.address1}</div>
          <div className="text-sm text-gray-600 text-pretty">
            {pharmacy.city}, {pharmacy.zip}
          </div>
        </InfoWindow>
      )}
    </>
  );
};

interface LocationMapProps {
  request: IRequest;
}

const calculateZoomLevel = (radiusInMeters: number) => {
  const buffer = 1500;
  const windowWidth = 500;

  const scale = 2;
  const zoom = 15;
  const radius = (radiusInMeters + buffer) * scale;
  const zoomHeight = Math.round(
    Math.log2((windowWidth * 360) / (radius * 256 * Math.cos((Math.PI / 180) * 37.7749))) + zoom,
  );
  return zoomHeight > 0 ? zoomHeight : 1;
};

const LocationMap = ({ request }: LocationMapProps) => {
  const pharmacyFinder = usePharmacyFinder(request);
  const queryClient = useQueryClient();
  const mapRef = useRef<google.maps.Map | null>(null);

  const [center, setCenter] = useState<google.maps.LatLngLiteral>({
    lat: request.location?.latitude || 37.7749,
    lng: request.location?.longitude || -122.4194,
  } as google.maps.LatLngLiteral);

  const [radius, setRadius] = useState(request.radius);
  const [radiusSlider, setRadiusSlider] = useState([radius]);
  const [calculatedZoomHeight, setCalculatedZoomHeight] = useState(calculateZoomLevel(request.radius));
  const [zoom, setZoom] = useState(calculatedZoomHeight);

  const handleRadiusChange = (newRadius: number) => {
    axiosClient
      .put(`/v1/requests/${request.id}`, {
        radius: newRadius,
      })
      .then((response) => {
        setRadius(newRadius);
        queryClient.setQueryData([`v1/requests/${request.id}`], response.data);
        pharmacyFinder.refetch();
        setCalculatedZoomHeight(calculateZoomLevel(response.data.radius));
      });
  };

  const pharmacies = pharmacyFinder?.data || [];

  const changeCenter = (newCenter: any) => {
    if (!newCenter) return;

    const payload = {
      request_id: request.id,
      latitude: newCenter.latLng?.lat(),
      longitude: newCenter.latLng?.lng(),
    };

    setCenter({
      lat: payload.latitude,
      lng: payload.longitude,
    });

    axiosClient.post(`/v1/locations`, payload).then((response) => {
      queryClient.setQueryData([`v1/requests/${request.id}`], response.data);
      pharmacyFinder.refetch();
      setCalculatedZoomHeight(calculateZoomLevel(response.data.radius));
    });
  };

  const handleSliderChange = (newRadius: [number]) => {
    setRadiusSlider(newRadius);
  };

  const handleSliderCommit = (newRadius: [number]) => {
    setRadiusSlider(newRadius);
    handleRadiusChange(newRadius[0]);
  };

  const debouncedChangeCenter = useDebouncedCallback(handleRadiusChange, 250);
  const radiusInMiles = Math.round(radiusSlider[0] * 0.000621371192 * 100) / 100;

  const handleZoomChanged = () => {
    if (mapRef.current) {
      setZoom(mapRef.current.getZoom()!);
    }
  };

  return (
    <>
      <APIProvider apiKey={import.meta.env.VITE_GOOGLE_MAPS_API_KEY}>
        <Map
          defaultCenter={center}
          mapId={'bf51a910020fa25a'}
          className="h-[350px] rounded-lg"
          defaultZoom={zoom}
          gestureHandling={'greedy'}
          disableDefaultUI={true}
          onZoomChanged={handleZoomChanged}
        >
          {pharmacies.map((pharmacy) => (
            <MarkerWithInfowindow key={pharmacy.id} pharmacy={pharmacy} />
          ))}
          <Circle
            radius={radiusSlider[0]}
            center={center}
            onRadiusChanged={(newRadius) => {
              setRadiusSlider([newRadius]);
              debouncedChangeCenter(newRadius);
            }}
            onDragEnd={changeCenter}
            strokeColor={'#8D5BFF'}
            strokeOpacity={1}
            strokeWeight={2}
            fillColor={'#8D5BFF'}
            fillOpacity={0.16}
            editable
            draggable
          />
          <AdvancedMarker position={center} title={'Request Location'}>
            <MdOutlineMyLocation className="w-8 h-8 -mb-4 text-brand-purple" />
          </AdvancedMarker>{' '}
        </Map>
      </APIProvider>
      <div className="flex items-center w-full gap-4 pt-4 pb-2">
        <div className="flex-none pl-2 text-sm text-gray-600">Travel Range</div>
        <div className="grow">
          <Slider
            value={radiusSlider}
            max={300000}
            step={200}
            onValueChange={handleSliderChange}
            onValueCommit={handleSliderCommit}
          />
        </div>
        <div className="flex-none">
          <Badge variant={'outline'} className="text-gray-500 border-gray-300">
            {radiusInMiles} Miles
          </Badge>
        </div>
      </div>
    </>
  );
};

export default LocationMap;
