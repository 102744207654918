import { useState } from 'react';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { MdOutlineClear } from 'react-icons/md';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input.tsx';
import { useToast } from '@/hooks/useToast';
import { IRequest } from '@/types.ts';
import axiosClient from '@/utils/axiosClient.ts';

interface LocationModalProps {
  request: IRequest;
}
const LocationInput = ({ request }: LocationModalProps) => {
  const { toast } = useToast();

  const [address, setAddress] = useState<string>('');

  const { placePredictions, getPlacePredictions } = usePlacesService({
    apiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
  });

  const handleAddressSelection = (givenAddress: any) => {
    const payload = {
      address: givenAddress.description,
      google_place_id: givenAddress.place_id,
      request_id: request.id,
    };

    axiosClient
      .post(`/v1/locations`, payload)
      .then(() => {
        window.location.reload();
      })
      .catch(() => {
        toast({
          title: 'Error',
          description: 'There was an error updating your location.',
        });
      });
  };

  const showDropdown = address !== '' && placePredictions.length > 0;

  const clearAddress = () => {
    setAddress(' ');
  };

  return (
    <>
      <div className="relative w-full">
        <div className="">
          <Input
            type="text"
            id="address"
            autoComplete="off"
            placeholder="Begin typing your address..."
            value={address.length > 0 ? address : request.location?.address || ''}
            className="w-full text-sm truncate bg-transparent border-none focus:ring-0 focus:border-0 focus:outline-none"
            onChange={(evt) => {
              setAddress(evt.target.value);

              getPlacePredictions({
                input: evt.target.value,
                types: ['address'],
              });
            }}
          />
        </div>

        {showDropdown && (
          <div className="absolute z-[9999] bg-white flex flex-col w-full border border-gray-700 divide-y rounded-md divide-primary">
            {placePredictions.map((item) => {
              return (
                <div
                  key={item.description}
                  className="px-2 py-2 text-xs text-gray-700 cursor-pointer hover:bg-primary w-fill hover:text-white "
                  onClick={() => handleAddressSelection(item)}
                >
                  {item.description}
                </div>
              );
            })}
          </div>
        )}
      </div>
      {address.length > 0 ||
        (request.location?.address && (
          <Button title={'Clear address'} onClick={clearAddress} className="px-2" variant="ghost">
            <MdOutlineClear className="w-5 h-5" />
          </Button>
        ))}
    </>
  );
};

export default LocationInput;
