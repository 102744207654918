import { loadStripe } from '@stripe/stripe-js';
import useMe from '@/hooks/useMe';
import axiosClient from '@/utils/axiosClient.ts';

interface RequestSummaryProps {
  requestId?: string;
}

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);

const SubscriptionCTA = ({ requestId }: RequestSummaryProps) => {
  const currentUser = useMe();

  const isMember = currentUser?.data?.membership_is_active;

  const generateMembershipCheckoutSession = async () => {
    axiosClient.post('/v1/memberships', { request_id: requestId }).then((response: any) => {
      if (response?.data?.stripe_session_id) {
        stripePromise.then((stripe) => {
          if (stripe) {
            stripe.redirectToCheckout({
              sessionId: response.data.stripe_session_id,
            });
          }
        });
      }
    });
  };

  if (isMember) {
    return null;
  }

  return (
    <>
      <div className="w-full bg-[#8D5BFF] shadow rounded-2xl p-8 text-white  border border-[#8D5BFF]">
        <div className="text-xl font-semibold leading-6">Needle Monthly Membership</div>
        <div className="flex flex-col items-center justify-between gap-4 mt-2 sm:gap-8 sm:flex-row ">
          <div className="text-sm grow">
            Sign up for a monthly membership for $35.00 and enjoy 50 calls each month. Credits never expire, and all
            credits roll over every month. Plus, you can purchase additional credits at discounted rates. You can cancel
            at anytime.
          </div>
          <button
            className="flex items-center flex-1 rounded-full bg-amber-200 h-9"
            onClick={() => {
              generateMembershipCheckoutSession();
            }}
          >
            <div className="inline-flex flex-col items-start justify-center gap-8 py-2 pl-4 pr-2 rounded-full bg-amber-200">
              <div className="text-base font-semibold leading-tight text-indigo-950">$35/mo</div>
            </div>
            <div className="inline-flex flex-col items-center justify-center gap-8 px-4 py-2 rounded-full bg-emerald-400">
              <div className="text-base font-semibold leading-tight text-indigo-950">Subscribe</div>
            </div>
          </button>
        </div>
      </div>
    </>
  );
};

export default SubscriptionCTA;
