import { useState } from 'react';
import axios from 'axios';
import { AlertCircle } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { Button } from '../ui/button';
import { Input } from '../ui/input';

const Login = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  const login = (e: any) => {
    e.preventDefault();
    setIsSaving(true);
    setError('');

    if (email.length > 0) {
      axios
        .post(`${import.meta.env.VITE_NEEDLE_API_URL}/v1/sessions/login`, {
          email: email,
        })
        .then((response) => {
          setIsSaving(false);
          if (response && response.data && response.data.user_id) {
            navigate(`/mfa/email/${email}/${response.data.user_id}`);
          }
        })
        .catch((error) => {
          console.log(error);
          setIsSaving(false);
          setError(error.response?.data?.errors[0] || 'System error occurred, contact us if this persists');
        });
    } else {
      setIsSaving(false);
      setError('A valid email address is required');
    }
  };

  return (
    <>
      <form onSubmit={login} className="flex flex-col justify-center gap-2 p-6 mx-auto sm:max-w-md">
        <div className="flex flex-col gap-2 mb-6">
          <h1 className="text-4xl font-semibold text-black">Needle CRM</h1>
          <h2 className="text-gray-600 sm:text-lg">Enter your employee credentials</h2>
        </div>

        {error && error.length > 0 && (
          <div className="mb-4">
            <Alert variant="error">
              <AlertCircle className="w-4 h-4" />
              <AlertTitle>Error</AlertTitle>
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          </div>
        )}

        <label htmlFor="email" className="text-slate-800 text-md">
          Email
        </label>
        <Input
          type="email"
          autoComplete="email"
          placeholder="Needle email address"
          autoFocus
          className="w-full rounded-full"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onBlur={(e) => setEmail(e.target.value)}
        />

        <div className="h-10 mt-4">
          <Button type="submit" color="purple" className="w-full rounded-full" disabled={isSaving || email.length == 0}>
            Send 6-digit code
          </Button>
        </div>
      </form>
    </>
  );
};

export default Login;
