import { useState } from 'react';
import { ICall } from '@/types';
import { cn } from '@/utils/utils';

const Transcript = ({ call }: { call: ICall }) => {
  const [showInReverse, setShowInReverse] = useState(true);

  const parseTimestamp = (timestamp: string): number => {
    if (timestamp.includes('T')) {
      // It's a full ISO 8601 timestamp
      return new Date(timestamp).getTime() / 1000;
    } else {
      // It's just seconds
      return parseFloat(timestamp);
    }
  };

  const sortedTranscript = call?.transcript?.sort((a, b) => {
    const aTime = parseTimestamp(a.created_at);
    const bTime = parseTimestamp(b.created_at);
    return showInReverse ? bTime - aTime : aTime - bTime;
  });

  const maxTime = sortedTranscript?.length ? parseTimestamp(sortedTranscript[0].created_at) : 0;
  const minTime = sortedTranscript?.length
    ? parseTimestamp(sortedTranscript[sortedTranscript.length - 1].created_at)
    : 0;

  const secondsToHumanReadable = (seconds: number) => {
    const minutes = Math.floor(Math.abs(seconds) / 60);
    const remainingSeconds = Math.floor(Math.abs(seconds) % 60);
    return `[${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}]`;
  };

  if (!call?.transcript) {
    return null;
  }

  return (
    <div className="p-2 mb-6 bg-white rounded-xl">
      <div className="flex justify-end">
        <button onClick={() => setShowInReverse(!showInReverse)} className="text-xs text-gray-500 underline">
          {showInReverse ? 'Show in order' : 'Show in reverse'}
        </button>
      </div>
      {call.transcript ? (
        <ul className="p-2 space-y-5 text-sm rounded-lg">
          {sortedTranscript.map((transcript) => {
            const userLabel = transcript?.user === 'user' ? 'Pharmacy' : 'Needle';
            const transcriptTime = parseTimestamp(transcript.created_at);

            const secondsSinceStart = showInReverse ? maxTime - transcriptTime : transcriptTime - minTime;

            return (
              <li
                key={transcript.id}
                className={cn('flex flex-row gap-2', transcript.user === 'user' ? ' text-gray-800' : ' text-gray-700')}
              >
                <div className="text-gray-500 ">
                  <span className="font-semibold text-gray-600 pr-0.5">
                    {secondsToHumanReadable(secondsSinceStart)}
                  </span>{' '}
                  <span className="pr-2 font-semibold leading-6 text-gray-800">{userLabel}:</span>
                  {transcript.text}
                </div>
              </li>
            );
          })}
        </ul>
      ) : (
        <div className="text-gray-400">No transcript available yet</div>
      )}
    </div>
  );
};

export default Transcript;
