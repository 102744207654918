import { useState } from 'react';
import { ICall, ITask } from '@/types';
import { cn } from '@/utils/utils';
import Call from '../Requests/Call';
import CallDisposition from '../Requests/CallDisposition';
import PhonePanel from './PhonePanel';

interface CallsPanelProps {
  task: ITask;
}
const CallsPanel = ({ task }: CallsPanelProps) => {
  const [selectedCall, setSelectedCall] = useState<ICall | null>(task.calls?.[task.calls.length - 1] || null);
  const calls = (task.calls as ICall[]) || ([] as ICall[]);

  const sortedCalls = calls.sort((a, b) => {
    return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
  });

  const handleSelected = (call: ICall) => {
    setSelectedCall(call);
  };

  return (
    <>
      <div className="flex gap-4">
        <div className="flex flex-1 gap-2 w-72">
          <ul className="divide-y rounded-lg  divide-y-gray-300 w-72">
            {sortedCalls.map((call, index) => (
              <li
                key={call.id || index}
                className={cn(
                  'border-l-2 cursor-pointer border-l-transparent hover:border-l-brand-purple rounded-lg',
                  selectedCall?.id === call.id && 'border-l-brand-purple',
                )}
                onClick={() => handleSelected(call)}
              >
                <Call call={call} />
              </li>
            ))}
            <li className="my-4 w-72">
              <PhonePanel task={task} />
            </li>
          </ul>
        </div>
        <div className="w-full grow">
          {selectedCall && task && <CallDisposition taskId={task.id} requestId={task.request_id} call={selectedCall} />}
        </div>
      </div>
    </>
  );
};

export default CallsPanel;
