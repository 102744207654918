import { MdLocationPin } from 'react-icons/md';
import LocationInput from '@/components/Location/LocationInput.tsx';
import LocationMap from '@/components/Location/LocationMap.tsx';
import { IRequest } from '@/types.ts';
import Section from '../Section';

interface LocationProps {
  request: IRequest;
}
const Location = ({ request }: LocationProps) => {
  return (
    <>
      <Section title="Location" className="pt-1">
        <div className="relative w-full pt-1">
          <LocationMap request={request} />

          <div className="absolute w-full top-2">
            <div className="px-2 mx-auto ">
              <div className="flex items-center w-full gap-1 px-3 py-0 rounded-md bg-white/85">
                <MdLocationPin className="w-6 h-6 text-brand-purple " />
                <LocationInput request={request} />
              </div>
            </div>
          </div>
        </div>
      </Section>
    </>
  );
};

export default Location;
