import { ArrowLeft } from 'lucide-react';
import { Link } from 'react-router-dom';
import { IRequest } from '@/types.ts';
import Section from '../Section.tsx';
import { Button } from '../ui/button.tsx';
import CreateOrderButton from './CreateOrderButton.tsx';
import MedicationAvailability from './MedicationAvailability.tsx';
import RequestOutcome from './RequestOutcome.tsx';

interface RequestShowProps {
  request: IRequest;
  setView: (view: string) => void;
  save: (data: any) => void;
}
const RequestShow = ({ request, setView }: RequestShowProps) => {
  return (
    <>
      <div className="flex items-center justify-between w-full h-18">
        <div className="flex items-center justify-start gap-1 mt-6 mb-6 sm:mt-6 ">
          <div className="mr-2 leading-snug text-gray-600 hover:text-violet-600">
            <Link to="/requests">
              <ArrowLeft />
            </Link>
          </div>
          <div className="hidden font-medium leading-snug text-gray-400 hover:text-violet-600 sm:inline-block">
            <Link to="/requests">Orders</Link>
          </div>
          <div className="hidden px-2 font-medium leading-snug text-gray-400 sm:inline-block">/</div>
          <div className="font-medium leading-snug text-violet-60 items-center0">
            Order #<span className="uppercase">{request.id.substring(0, 6)}</span>
          </div>
        </div>
        <div className="flex items-center justify-end gap-2 ">
          {request.tasks.length != 0 && (
            <Button onClick={() => setView('edit')} className="gap-2">
              Edit
            </Button>
          )}

          <CreateOrderButton requestId={request.id} />
        </div>
      </div>

      <div className="flex flex-col justify-between gap-4 mt-4 mb-8 md:flex-row sm:gap-8">
        <div className="flex flex-col">
          <div className="text-3xl font-bold leading-8 text-neutral-800 sm:text-2xl">
            {request.status_details.title}
          </div>
          <div className="max-w-3xl mt-2 text-sm font-normal leading-5 text-gray-500 sm:mt-3 md:mt-2 lg:mt-2 xl:mt-2 ">
            {request.status_details.text}
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-12">
        <RequestOutcome request={request} />
        <Section title="Notes">
          <div className="w-full p-2 pt-0 ">
            <div className="whitespace-pre-wrap text-md">
              {request.needle_note || 'Notes will appear here when we have finalized the order.'}
            </div>
          </div>
        </Section>
        <MedicationAvailability request={request} />
      </div>
    </>
  );
};

export default RequestShow;
