import React, { useState, useEffect } from 'react';
import { MagnifyingGlassIcon } from '@heroicons/react/16/solid';
import { RankingInfo, rankItem } from '@tanstack/match-sorter-utils';
import {
  ColumnDef,
  ColumnFiltersState,
  FilterFn,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { useParams } from 'react-router';
import DataTableRenderer from '@/components/DataTable/DataTableRenderer';
import { TableFilters } from '@/components/Requests/DataTable/TableFilters';
import { Input } from '@/components/ui/input';

declare module '@tanstack/react-table' {
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
  }
  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  const itemRank = rankItem(row.getValue(columnId), value);
  addMeta({
    itemRank,
  });
  return itemRank.passed;
};

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  onRowClick?: (row: TData) => void;
  selectedTaskId?: string;
}

export function PharmaciesTaskTable<TData extends { id: string }, TValue>({
  columns,
  data,
  onRowClick,
  selectedTaskId,
}: DataTableProps<TData, TValue>) {
  const { taskId } = useParams();
  const [columnVisibility, setColumnVisibility] = useState({
    'Task ID': false,
    'Pharmacy ID': false,
    'Medication ID': false,
  });
  const [columnOrder, setColumnOrder] = useState<string[]>(columns.map((c) => c.id) as string[]);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [selectedRowIndex, setSelectedRowIndex] = useState(-1);

  const table = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    initialState: {
      columnOrder: ['Pharmacy', 'Stock'],
    },
    state: {
      sorting,
      columnFilters,
      columnOrder,
      columnVisibility,
      globalFilter,
    },
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: 'fuzzy',
    getCoreRowModel: getCoreRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onColumnOrderChange: setColumnOrder,
    onColumnVisibilityChange: setColumnVisibility as any,
  });

  useEffect(() => {
    if (selectedTaskId) {
      const index = table.getRowModel().rows.findIndex((row) => row.original.id === selectedTaskId);
      setSelectedRowIndex(index);
    }
  }, [selectedTaskId, table]);

  const handleRowChange = (index: number) => {
    const row = table.getRowModel().rows[index]?.original;
    if (row && onRowClick) {
      onRowClick(row);
    }
    setSelectedRowIndex(index);
  };

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (taskId) {
        if (e.key === 'ArrowDown') {
          setSelectedRowIndex((prevIndex) => {
            const newIndex = Math.min(prevIndex + 1, table.getRowModel().rows.length - 1);
            handleRowChange(newIndex);
            return newIndex;
          });
        } else if (e.key === 'ArrowUp') {
          setSelectedRowIndex((prevIndex) => {
            const newIndex = Math.max(prevIndex - 1, 0);
            handleRowChange(newIndex);
            return newIndex;
          });
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [taskId, table]);

  return (
    <div className="w-full">
      <div className="flex justify-end gap-4 mb-2">
        <div className="flex items-center justify-between h-8 border border-gray-300 rounded-full">
          <div className="flex items-center justify-between h-6 px-2 py-0">
            <MagnifyingGlassIcon className={'w-5 h-5 text-brand-purple'} />
            <Input
              type="text"
              placeholder="Search"
              value={globalFilter ?? ''}
              onChange={(e) => setGlobalFilter(String(e.target.value))}
              className="text-xs font-medium placeholder-gray-400 bg-transparent border-none w-28 focus-visible:ring-none focus-visible:ring-0 focus-visible:ring-white focus-visible:outline-none focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50"
            />
          </div>
          {data.length >= 0 && (
            <div className="flex-none pr-4 text-xs text-[#898b8e] font-normal flex">
              {table.getFilteredRowModel().rows.length} Results
            </div>
          )}
        </div>
        <TableFilters table={table} />
      </div>

      <DataTableRenderer
        table={table}
        columns={columns}
        onRowClick={(row) => {
          if (onRowClick) {
            onRowClick(row);
          }
          setSelectedRowIndex(table.getRowModel().rows.findIndex((r) => r.original === row));
        }}
        selectedRowIndex={selectedRowIndex}
        selectedTaskId={selectedTaskId}
      />
    </div>
  );
}
