import React, { useEffect, useState } from 'react';
import { useModal } from '@ebay/nice-modal-react';
import { FaPen } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import HeroAvatar from '@/assets/hero_avatar.svg';
import MembershipCardBkgDark from '@/assets/membership_card_bkg.svg';
import MembershipCardBkgActive from '@/assets/membership_card_bkg_active.svg';
import useMe from '@/hooks/useMe';
import axiosClient from '@/utils/axiosClient.ts';
import { formatPhoneNumber, USDollar } from '@/utils/helpers';
import Section from '../Section';
import { Button } from '../ui/button';
import EditUserInfo from './EditUserInfo';

const MembershipCard: React.FC = () => {
  const { data: currentUser, refetch } = useMe();
  const creditsModal = useModal('credits-modal');
  const [editingField, setEditingField] = useState<'name' | 'phone' | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const [billingPortalUrl, setBillingPortalUrl] = useState('');

  const hasMembership = currentUser?.membership_is_active;
  const background = hasMembership ? MembershipCardBkgActive : MembershipCardBkgDark;

  useEffect(() => {
    setIsLoading(true);

    axiosClient.get(`${import.meta.env.VITE_NEEDLE_API_URL}/v1/stripe/portal`).then((response) => {
      if (response.statusText === 'OK') {
        setBillingPortalUrl(response.data.url);
      }
      setIsLoading(false);
    });
  }, []);

  return (
    <>
      <Section title={'Membership'}>
        <div className="flex flex-col items-start justify-start w-full gap-6 rounded-lg md:flex-col ">
          <div className="relative w-full">
            <img src={background} className="object-cover w-full h-full " />
            <div className="-mt-20">
              <div className="flex items-end self-stretch justify-between p-5 ">
                <div className="relative border-2 border-white rounded-full shadow ">
                  <img src={HeroAvatar} className="w-28" />
                </div>
                <div className="flex items-start justify-start rounded-full ">
                  <div className="inline-flex flex-col items-start justify-center h-6 gap-8 px-3 py-2 rounded-full">
                    {hasMembership ? (
                      <div className="inline-flex items-start justify-start h-6 rounded-full bg-violet-500">
                        <div className="h-6 px-3 py-2 bg-amber-200 rounded-[99px] flex-col justify-center items-start gap-8 inline-flex">
                          <div className="text-indigo-950 text-sm font-medium  leading-[14px]">Membership</div>
                        </div>
                        <div className="h-6 pl-1.5 pr-3 py-2 rounded-full flex-col justify-center items-start gap-8 inline-flex">
                          <div className="text-white text-sm font-medium  leading-[14px]">Active</div>
                        </div>
                      </div>
                    ) : (
                      <Button
                        variant={'outline'}
                        className="text-gray-900 text-md font-medium leading-[14px] bg-neutral-300 px-3 py-2 rounded-full"
                        onClick={() => creditsModal.show()}
                      >
                        No subscription
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col items-start self-stretch justify-start gap-6 p-8 grow shrink basis-0">
              <div className="inline-flex flex-col items-start justify-end gap-1 grow shrink basis-0">
                <div className="w-full text-sm font-medium text-left opacity-80 text-slate-600">
                  Joined Needle:
                  {hasMembership && billingPortalUrl && (
                    <Button variant={'ghost'} asChild disabled={isLoading}>
                      <Link to={billingPortalUrl}>
                        <FaPen className="-ml-2 cursor-pointer" />
                      </Link>
                    </Button>
                  )}
                </div>
                <div className="flex justify-end w-full text-lg font-semibold leading-snug text-gray-900">
                  {hasMembership ? (
                    <div>
                      {new Date(currentUser?.created_at).toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                      })}
                    </div>
                  ) : (
                    <div>Not a member</div>
                  )}
                </div>
              </div>
              <div className="inline-flex items-start self-stretch justify-start gap-6">
                <div className="inline-flex flex-col items-start justify-start gap-1 grow shrink basis-0">
                  <div className="flex items-center opacity-80 text-slate-600 text-sm font-medium leading-[16.80px]">
                    Name:
                    <FaPen className="ml-1 cursor-pointer" onClick={() => setEditingField('name')} />
                  </div>
                  {editingField === 'name' ? (
                    <EditUserInfo
                      field="name"
                      onClose={() => {
                        setEditingField(null);
                        refetch();
                      }}
                    />
                  ) : (
                    <div className="text-lg font-semibold leading-snug text-gray-900">
                      {currentUser?.first_name} {currentUser?.last_name}
                    </div>
                  )}
                </div>
              </div>
              <div className="inline-flex flex-col items-start self-stretch justify-start gap-6">
                <div className="inline-flex flex-col items-start justify-start gap-1 grow shrink basis-0">
                  <div className="opacity-80 text-slate-600 text-sm font-medium leading-[16.80px]">Email:</div>
                  <div className="text-lg font-semibold leading-snug text-gray-900">{currentUser?.email}</div>
                </div>
                <div className="inline-flex flex-col items-start justify-start gap-1 grow shrink basis-0">
                  <div className="flex items-center opacity-80 text-slate-600 text-sm font-medium leading-[16.80px]">
                    Phone:
                    <FaPen className="ml-1 cursor-pointer" onClick={() => setEditingField('phone')} />
                  </div>
                  {editingField === 'phone' ? (
                    <EditUserInfo
                      field="phone"
                      onClose={() => {
                        setEditingField(null);
                        refetch();
                      }}
                    />
                  ) : (
                    <div className="text-lg font-semibold leading-snug text-gray-900">
                      {formatPhoneNumber(currentUser?.phone)}
                    </div>
                  )}
                </div>
              </div>
              <div className="flex flex-col items-start self-stretch justify-start gap-2 mt-4">
                <div className="flex flex-col items-start justify-start gap-1">
                  <div className="opacity-80 text-slate-600 text-sm font-medium leading-[16.80px]">
                    Available Credits
                  </div>
                  <div className="text-2xl font-semibold leading-snug text-gray-900">
                    {USDollar.format(currentUser?.total_credits)}
                  </div>
                </div>
              </div>
            </div>
            {!hasMembership && (
              <div className="p-4">
                <Button
                  className="w-full mt-2 text-center underline rounded-full text-brand-dark-blue bg-brand-green hover:text-white "
                  onClick={() => {
                    creditsModal.show();
                  }}
                >
                  Subscribe ($35)
                </Button>
              </div>
            )}
          </div>
        </div>
      </Section>
    </>
  );
};

export default MembershipCard;
