import { Column } from '@tanstack/react-table';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa6';
import { Button } from '@/components/ui/button';
import { cn } from '@/utils/utils';

interface DataTableColumnHeaderProps<TData, TValue> extends React.HTMLAttributes<HTMLDivElement> {
  column: Column<TData, TValue>;
  title: string;
}

export function DataTableColumnHeader<TData, TValue>({
  column,
  title,
  className,
}: DataTableColumnHeaderProps<TData, TValue>) {
  const handleSortToggle = () => {
    if (column.getIsSorted() === 'asc') {
      column.toggleSorting(true);
    } else {
      column.toggleSorting(false);
    }
  };

  return (
    <div className={cn('flex items-center space-x-2 ', className)}>
      <Button variant="ghost" size="sm" className="h-8 -ml-3 cursor-pointer" onClick={handleSortToggle}>
        <span>{title}</span>
        {column.getIsSorted() === 'desc' ? (
          <FaArrowDown className="ml-2" />
        ) : column.getIsSorted() === 'asc' ? (
          <FaArrowUp className="ml-2" />
        ) : (
          <FaArrowDown className="ml-2" />
        )}
      </Button>
    </div>
  );
}
