import React, { useState, ChangeEvent, FormEvent } from 'react';
import { Button } from '@/components/ui/button';
import AxiosClient from '@/utils/axiosClient';

interface CreditDebitToolProps {
  userId: string;
  onClose: () => void;
}

const CreditDebitTool: React.FC<CreditDebitToolProps> = ({ userId, onClose }) => {
  const [amount, setAmount] = useState('');
  const [note, setNote] = useState('');
  const [transactionType, setTransactionType] = useState<'addition' | 'utilization'>('addition');

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const response = await AxiosClient.post('/v1/credits/manage', {
        user_id: userId,
        amount: parseFloat(amount),
        note,
        transaction_type: transactionType,
      });
      console.log('New total credits:', response.data.total_credits);
      onClose();
    } catch (error) {
      console.error('Failed to process the transaction', error);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-75">
      <div className="bg-white p-6 rounded-lg shadow-lg w-96">
        <h2 className="text-xl font-bold mb-4">Credit/Debit User</h2>
        <form onSubmit={handleSubmit} className="flex flex-col gap-4">
          <label>
            Amount:
            <input
              type="number"
              value={amount}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setAmount(e.target.value)}
              className="w-full p-2 mt-1 border rounded"
              required
            />
          </label>
          <label>
            Note:
            <input
              type="text"
              value={note}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setNote(e.target.value)}
              className="w-full p-2 mt-1 border rounded"
              required
            />
          </label>
          <div className="flex gap-4">
            <label className="flex items-center">
              <input
                type="radio"
                value="addition"
                checked={transactionType === 'addition'}
                onChange={() => setTransactionType('addition')}
                className="mr-2"
              />
              Credit
            </label>
            <label className="flex items-center">
              <input
                type="radio"
                value="utilization"
                checked={transactionType === 'utilization'}
                onChange={() => setTransactionType('utilization')}
                className="mr-2"
              />
              Debit
            </label>
          </div>
          <div className="flex justify-end gap-4">
            <Button type="button" onClick={onClose} variant="outline">
              Cancel
            </Button>
            <Button type="submit">Submit</Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreditDebitTool;
