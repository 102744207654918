import { useState, useEffect } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { MagnifyingGlassIcon } from '@heroicons/react/16/solid';
import { MapPin, Phone } from 'lucide-react';
import { Link } from 'react-router-dom';
import Modal from '@/components/Modals/Modal';
import { StatusIndicator } from '@/components/Requests/StatusIndicator';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { IMedication, IRequest } from '@/types';
import { formatPhoneNumber } from '@/utils/helpers';
import { cn } from '@/utils/utils';

interface MedicationResultsModalProps {
  request: IRequest;
  medication: IMedication;
}

const MedicationResultsModal = NiceModal.create(({ request, medication }: MedicationResultsModalProps) => {
  const modal = useModal('medication-results-modal');
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState('All');
  const [medicationFilters, setMedicationFilters] = useState<string[]>([]);

  useEffect(() => {
    setMedicationFilters([medication.id]);
  }, [medication]);

  const tasks = request.tasks.filter((task) => medicationFilters.includes(task.medication_id));

  const filtersAvailable = ['All', 'In Stock', 'Out of Stock'];

  const sortedTasks = tasks.sort((a, b) => {
    if (a.pharmacy.name < b.pharmacy.name) {
      return -1;
    }
    if (a.pharmacy.name > b.pharmacy.name) {
      return 1;
    }
    return 0;
  });

  const filteredTasks = sortedTasks.filter((task) => task.pharmacy.name.toLowerCase().includes(search.toLowerCase()));

  const filteredTasksByStatus = filteredTasks.filter((task) => {
    if (filter === 'All') {
      return true;
    }

    if (request.status !== 'completed') {
      return false;
    }

    if (task.stock_status === null) {
      return task.status === filter.replace(/ /g, '_').toLowerCase();
    }

    return task.stock_status === filter.replace(/ /g, '_').toLowerCase();
  });

  const handleFilter = (filter: string) => {
    setFilter(filter);
  };

  const toggleMedicationFilter = (medicationId: string) => {
    setMedicationFilters((prev) =>
      prev.includes(medicationId) ? prev.filter((id) => id !== medicationId) : [...prev, medicationId],
    );
  };

  return (
    <Modal modal={modal} title={'Pharmacy Availability'} wide>
      <div className="flex items-center justify-between h-10 border border-gray-300 rounded-md">
        <div className="flex items-center justify-between h-full px-2">
          <MagnifyingGlassIcon className="w-5 h-5 text-brand-purple" />
          <Input
            type="text"
            placeholder="Search for a pharmacy"
            value={search ?? ''}
            onChange={(e) => setSearch(String(e.target.value))}
            className="w-full text-sm placeholder-gray-400 bg-transparent border-none focus:ring-0 focus:outline-none disabled:cursor-not-allowed disabled:opacity-50"
          />
        </div>
      </div>

      <div className="mt-4">
        <details className="border border-gray-300 rounded-md bg-gray-50">
          <summary className="px-4 py-2 text-base font-medium text-gray-700 cursor-pointer select-none">
            Filters
          </summary>
          <div className="flex flex-col gap-4 p-4">
            {/* Status Filters Section */}
            <div className="flex flex-col gap-2">
              <h3 className="text-base font-medium text-gray-700">Filter by Status</h3>
              <div className="flex flex-row flex-wrap gap-2">
                {filtersAvailable.map((f) => (
                  <Button
                    variant={'outline'}
                    size="sm"
                    className={cn(
                      'gap-2 rounded-md',
                      f === filter ? 'bg-brand-purple text-white' : 'bg-white text-gray-800',
                    )}
                    key={f}
                    onClick={() => handleFilter(f)}
                  >
                    {f} (
                    {
                      filteredTasks.filter((task) => {
                        if (f === 'All') {
                          return true;
                        }

                        if (request.status !== 'completed') {
                          return false;
                        }

                        if (task.stock_status === null) {
                          return task.status === f.replace(/ /g, '_').toLowerCase();
                        }

                        return task.stock_status === f.replace(/ /g, '_').toLowerCase();
                      }).length
                    }
                    )
                  </Button>
                ))}
              </div>
            </div>

            <div className="flex flex-col gap-2">
              <h3 className="text-base font-medium text-gray-700">Filter by Medication(s)</h3>
              <div className="flex flex-row flex-wrap gap-2">
                {request.medications.map((med) => (
                  <Button
                    key={med.id}
                    variant={'outline'}
                    size="sm"
                    className={cn(
                      'gap-2 rounded-md',
                      medicationFilters.includes(med.id) ? 'bg-brand-purple text-white' : 'bg-white text-gray-800',
                    )}
                    onClick={() => toggleMedicationFilter(med.id)}
                  >
                    {med.medication} {med.dosage} {med.quantity} {med.dosage_form}
                  </Button>
                ))}
              </div>
            </div>
          </div>
        </details>
      </div>

      <div className="mt-4">
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 ">
          {filteredTasksByStatus.map((task) => {
            const formatGoogleMapsLink = () => {
              const address = `${task.pharmacy.address1} ${task.pharmacy.city} ${task.pharmacy.state} ${task.pharmacy.zip}`;
              const encodedAddress = encodeURIComponent(address);
              return `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`;
            };

            const mapUrl = formatGoogleMapsLink();

            return (
              <div key={task.id} className="p-4 border border-gray-300 rounded-md">
                <div className="text-base font-semibold leading-6">{task.pharmacy.name}</div>
                <div className="text-sm text-gray-600">{task.pharmacy.address1}</div>
                <div className="text-sm text-gray-600">
                  {task.pharmacy.city} {task.pharmacy.state} {task.pharmacy.zip}
                </div>
                <div className="my-1 text-sm text-gray-600">{formatPhoneNumber(task.pharmacy.phone)}</div>

                <div className="flex flex-col gap-2 mt-4">
                  {request.medications.map((med) => {
                    const theTask = request.tasks.find(
                      (t) => t.medication_id === med.id && t.pharmacy_id === task.pharmacy.id,
                    );

                    return (
                      <div key={med.id} className="flex flex-col gap-2">
                        <div className="flex items-center justify-between">
                          <div className="text-base font-semibold text-gray-800">
                            {med.medication} {med.dosage}
                            <div className="text-sm font-medium text-gray-500">
                              {med.quantity} {med.dosage_form}
                            </div>
                          </div>

                          <div className="text-sm text-gray-800">
                            {request.status === 'completed' && theTask?.stock_status && (
                              <StatusIndicator status={theTask?.stock_status as any} />
                            )}
                          </div>
                        </div>
                        {request.status === 'completed' && theTask?.notes && (
                          <div className="w-full p-2 bg-gray-100 rounded-md">
                            <div className="text-sm text-gray-800">{theTask.notes}</div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>

                <div className="flex flex-col justify-end grow">
                  <div className="flex flex-row gap-2 mt-4">
                    <Button variant={'outline'} className="w-full gap-2 rounded-md" asChild>
                      <Link to={`tel:${formatPhoneNumber(task.pharmacy.phone)}`}>
                        <Phone className="h-4 text-brand-purple" />
                        Call
                      </Link>
                    </Button>
                    <Button variant={'outline'} className="w-full gap-2 rounded-md">
                      <Link to={mapUrl} target="_blank" className="flex items-center gap-2">
                        <MapPin className="h-4 text-brand-purple" />
                        Map
                      </Link>
                    </Button>
                  </div>
                </div>
              </div>
            );
          })}

          {filteredTasksByStatus.length === 0 && (
            <div className="col-span-2 p-4 border border-gray-300 rounded-md">
              <div className="text-base font-semibold leading-6">No pharmacies found with these filters</div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
});

export default MedicationResultsModal;
