import { IUser } from '@/types.ts';
import { getUrl } from '@/utils/api';

interface UserSearchResponse {
  users: IUser[];
  total_count: number;
  current_page: number;
  total_pages: number;
}

function useUsers(
  filter?: string,
  page: number = 1,
  perPage: number = 50,
  sortBy: 'email' | 'name' | 'phone' | 'created_at' | 'plan_end_at' = 'created_at',
  sortOrder: 'asc' | 'desc' = 'desc',
) {
  let url = `admin/users/search?page=${page}&per_page=${perPage}&sort_by=${sortBy}&sort_order=${sortOrder}`;

  if (filter) {
    url += `&query=${encodeURIComponent(filter)}`;
  }

  const query = getUrl(url);

  const processedData: UserSearchResponse = query.data
    ? {
        users: query.data.users as IUser[],
        total_count: query.data.total_count,
        current_page: query.data.current_page,
        total_pages: query.data.total_pages,
      }
    : {
        users: [],
        total_count: 0,
        current_page: 1,
        total_pages: 1,
      };

  return { ...query, data: processedData };
}

export default useUsers;
