import { useParams } from 'react-router';
import Request from '@/components/Admin/Requests/Request';
import Loading from '@/components/Loading.tsx';
import useRequest from '@/hooks/useRequest.ts';

const RequestLoader = () => {
  const { requestId } = useParams();
  const request = useRequest(requestId as string, true);

  if (request?.isLoading || !request?.data) {
    return <Loading />;
  }

  return <Request request={request.data} />;
};

export default RequestLoader;
