import { IPharmacy } from '@/types';

const PharmacyCard = ({ pharmacy }: { pharmacy: IPharmacy }) => {
  return (
    <div>
      <pre>{JSON.stringify(pharmacy, null, 2)}</pre>
    </div>
  );
};

export default PharmacyCard;
