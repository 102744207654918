/* eslint-disable */
// @ts-nocheck
import { useMemo, useState } from 'react';
import { MagnifyingGlassIcon } from '@heroicons/react/16/solid';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa6';
import { useQueryClient } from 'react-query';
import PharmacyIcon from '@/components/PharmacyIcon';
import Section from '@/components/Section';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Switch } from '@/components/ui/switch';
import usePharmacyFinder from '@/hooks/usePharmacyFinder';
import { IPharmacy, IRequest } from '@/types';
import axiosClient from '@/utils/axiosClient.ts';
import { cn } from '@/utils/utils';

interface DataTableProps {
  request: IRequest;
}

export function PharmaciesUnpaidTable({ request }: DataTableProps) {
  const pharmacyFinder = usePharmacyFinder(request);
  const queryClient = useQueryClient();

  const [pharmacySorted, setPharmacySorted] = useState<string | null>(null);
  const [distanceSorted, setDistanceSorted] = useState<string | null>('asc');
  const [toggleSorted, setToggleSorted] = useState<string | null>(null);

  const [searchFilter, setSearchFilter] = useState('');
  const [globalSwitchOn, setGlobalSwitchOn] = useState<boolean>(false);

  const pharmacies = pharmacyFinder?.data?.filter((pharmacy) => pharmacy.source !== 'purchased') || [];

  const filteredPharmacies = useMemo(() => {
    const filterBySearch = (pharmacy: IPharmacy) => {
      let search = searchFilter.toLowerCase();
      let negate = false;

      if (search.startsWith('!')) {
        if (search.length < 2) {
          return true;
        }
        negate = true;
        search = search.substring(1);
      }
      const matches =
        pharmacy.id.toLowerCase().includes(search) ||
        pharmacy.name.toLowerCase().includes(search) ||
        pharmacy.address1.toLowerCase().includes(search) ||
        pharmacy.city.toLowerCase().includes(search) ||
        pharmacy.state.toLowerCase().includes(search) ||
        pharmacy.phone.toLowerCase().includes(search) ||
        pharmacy.zip.toLowerCase().includes(search);

      return negate ? !matches : matches;
    };

    const sortByField = (a: IPharmacy, b: IPharmacy, field: keyof IPharmacy) => {
      if (field === 'distance_miles') {
        const aDistance = parseFloat(a[field] as unknown as string);
        const bDistance = parseFloat(b[field] as unknown as string);
        return (aDistance - bDistance) * (distanceSorted === 'asc' ? 1 : -1);
      } else if (typeof a[field] === 'string' && typeof b[field] === 'string') {
        if (a[field] < b[field]) return pharmacySorted === 'asc' ? -1 : 1;
        if (a[field] > b[field]) return pharmacySorted === 'asc' ? 1 : -1;
      }
      return 0;
    };

    return pharmacies.filter(filterBySearch).sort((a, b) => {
      if (distanceSorted) return sortByField(a, b, 'distance_miles');
      if (pharmacySorted) return sortByField(a, b, 'name');
      if (toggleSorted) return (a.source === 'selected' ? -1 : 1) * (toggleSorted === 'asc' ? 1 : -1);
      return 0;
    });
  }, [searchFilter, pharmacySorted, distanceSorted, toggleSorted, pharmacies]);

  const toggleSelect = (type: string) => {
    axiosClient
      .post(`/v2/request_pharmacies/${request.id}/toggle`, {
        toggle: type,
      })
      .then((response) => {
        request.pharmacies = response.data.filter((pharmacy: IPharmacy) => pharmacy.source === 'selected');
        queryClient.setQueryData([`v1/requests/${request.id}`], request);
        pharmacyFinder.refetch();
      });
  };

  const handlePharmacySelected = (pharmacy: IPharmacy) => {
    if (!pharmacy) return;

    const payload = {
      request_id: request.id,
      pharmacy_id: pharmacy.id,
    };

    queryClient.setQueryData(
      [`v2/request_pharmacies/${request.id}/finder`],
      pharmacyFinder.data?.map((p) => {
        if (p.id === pharmacy.id) {
          p.source = p.source === 'selected' ? 'radius' : 'selected';
        }
        return p;
      }),
    );

    queryClient.setQueryData([`v1/requests/${request.id}`], {
      ...request,
      pharmacies: pharmacyFinder.data?.filter((p) => p.source === 'selected'),
    });

    axiosClient.post(`/v2/request_pharmacies`, payload).then((response) => {
      pharmacy.source = pharmacy.source === 'selected' ? 'radius' : 'selected';
      queryClient.setQueryData([`v2/request_pharmacies/${request.id}/finder`], response.data);
      request.pharmacies = response.data.filter((pharmacy: IPharmacy) => pharmacy.source === 'selected');
      queryClient.setQueryData([`v1/requests/${request.id}`], request);
    });
  };

  const handleSortToggle = () => {
    setPharmacySorted(pharmacySorted === 'asc' ? 'desc' : 'asc');
    setDistanceSorted(null);
    setToggleSorted(null);
  };

  const handleDistanceSortToggle = () => {
    setDistanceSorted(distanceSorted === 'asc' ? 'desc' : 'asc');
    setPharmacySorted(null);
    setToggleSorted(null);
  };

  return (
    <Section
      title={'Pharmacies'}
      description={'Select the pharmacies you want us to call.'}
      rightSide={
        <>
          <div className="flex justify-end gap-4">
            <div className="flex items-center justify-between h-8 border border-gray-300 rounded-full">
              <div className="flex items-center justify-between h-6 px-2 py-0">
                <MagnifyingGlassIcon className={'w-5 h-5 text-brand-purple'} />
                <Input
                  type="text"
                  placeholder="Search"
                  value={searchFilter}
                  onChange={(e) => setSearchFilter(String(e.target.value))}
                  className="text-xs font-medium placeholder-gray-400 bg-transparent border-none w-28 focus-visible:ring-none focus-visible:ring-0 focus-visible:ring-white focus-visible:outline-none focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50"
                />
              </div>
              {filteredPharmacies.length >= 0 && (
                <div className="flex-none pr-4 text-xs text-[#898b8e] font-normal flex">
                  {filteredPharmacies.length} Results
                </div>
              )}
            </div>
          </div>
        </>
      }
    >
      <div className="w-full border border-gray-300 rounded-lg min-h-32">
        <div className="flex flex-col w-full ">
          <div className="flex flex-col bg-white border-b rounded-tl-lg rounded-tr-lg border-b-gray-300">
            <div className="flex flex-row items-center gap-4 px-2 py-2 text-xs cursor-pointer">
              <div className=" grow min-w-[40%] max-w-[40%]">
                <div className="flex items-center space-x-2">
                  <Button
                    variant="ghost"
                    size="sm"
                    className="items-center h-8 gap-2 font-normal cursor-pointer"
                    onClick={handleSortToggle}
                  >
                    <span>Pharmacy</span>
                    {pharmacySorted === 'desc' ? (
                      <FaArrowDown className="text-gray-600" />
                    ) : pharmacySorted === 'asc' ? (
                      <FaArrowUp className="text-gray-600" />
                    ) : (
                      <FaArrowDown className="text-gray-600" />
                    )}
                  </Button>
                </div>
              </div>
              <div className="flex justify-start grow">
                <div className="flex items-center space-x-2">
                  <Button
                    variant="ghost"
                    size="sm"
                    className="items-center h-8 gap-2 -ml-3 font-normal cursor-pointer"
                    onClick={handleDistanceSortToggle}
                  >
                    <span>Address</span>
                    {distanceSorted === 'desc' ? (
                      <FaArrowDown className="text-gray-600" />
                    ) : distanceSorted === 'asc' ? (
                      <FaArrowUp className="text-gray-600" />
                    ) : (
                      <FaArrowDown className="text-gray-600" />
                    )}
                  </Button>
                </div>
              </div>
              <div className="flex justify-end flex-1 gap-2 mr-2">
                <Switch
                  className="border-brand-purple/50"
                  defaultChecked={globalSwitchOn}
                  onCheckedChange={() => {
                    setGlobalSwitchOn(!globalSwitchOn);
                    toggleSelect(globalSwitchOn ? 'unselect' : 'select');
                  }}
                />
              </div>
            </div>
          </div>
          <ScrollArea className="h-[calc(50vh)]">
            <div className="flex flex-col w-full divide-y divide-y-gray-300">
              {filteredPharmacies.map((pharmacy) => (
                <div
                  key={pharmacy.id}
                  onClick={() => handlePharmacySelected(pharmacy)}
                  className={cn(
                    'flex flex-row items-center gap-3 px-2 py-2 text-xs cursor-pointer hover:bg-gray-50 ',
                    pharmacy.source === 'selected' && 'bg-[#f8f5ff] ',
                  )}
                >
                  <PharmacyIcon pharmacy={pharmacy} />
                  <div className="flex flex-col gap-2 sm:items-center sm:flex-row grow">
                    <div className="flex-1 text-lg font-semibold text-pretty ">{pharmacy.name}</div>
                    <div className="flex justify-start flex-1">
                      <div className="flex flex-col text-xs truncate">
                        <div className="leading-5 truncate ">{pharmacy.address1}</div>
                        <div className="truncate">
                          {pharmacy.city}, {pharmacy.state}
                        </div>
                        <div className="mt-1.5 font-semibold">
                          <>{parseFloat(pharmacy.distance_miles as any).toFixed(2)} Miles</>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="pr-2">
                    <Switch checked={globalSwitchOn || pharmacy.source === 'selected'} />
                  </div>
                </div>
              ))}

              {filteredPharmacies.length === 0 && (
                <div className="flex flex-col max-w-xl gap-2 p-8 mx-auto text-center text-pretty">
                  <div className="font-semibold">No pharmacies found with your criteria.</div>

                  <div>
                    Try expanding your search radius by adjusting the circle on the map or moving the distance slider.
                  </div>
                </div>
              )}
            </div>
          </ScrollArea>
        </div>
      </div>
    </Section>
  );
}
