import { useState } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { ITicket } from '@/types';
import axiosClient from '@/utils/axiosClient.ts';
import Modal from '../../Modals/Modal';

interface PendingTicketModalProps {
  ticket: ITicket;
}

const PendingTicketModal = NiceModal.create(({ ticket }: PendingTicketModalProps) => {
  const [inputRequestId, setInputRequestId] = useState('');
  const modal = useModal('pending-ticket-modal');

  const updateTicket = () => {
    if (inputRequestId !== ticket.request_id) {
      alert('Request ID does not match');
      return;
    }

    axiosClient
      .put(`/admin/tickets/${ticket.id}/schedule`)
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  return (
    <Modal
      modal={modal}
      title={'Approve Ticket'}
      description={'Your approval is needed. Check the request, medications, locations, pharmacies, notes.'}
    >
      <div className="flex flex-col mb-4">
        <Label className="text-sm text-gray-600">Enter the Request ID to confirm</Label>
        <Input
          type="text"
          className="p-2 border border-gray-300 rounded"
          onChange={(e) => setInputRequestId(e.target.value)}
        />
      </div>

      <Button
        className="w-full"
        onClick={updateTicket}
        disabled={!inputRequestId || inputRequestId !== ticket.request_id}
      >
        I Approve, Run AI
      </Button>
    </Modal>
  );
});

export default PendingTicketModal;
