import { useState } from 'react';
import { useModal } from '@ebay/nice-modal-react';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { GoNote } from 'react-icons/go';
import Pluralize from 'react-pluralize';
import MedIcon from '@/components/MedIcon';
import { Badge } from '@/components/ui/badge';
import { HoverCard, HoverCardContent, HoverCardTrigger } from '@/components/ui/hover-card';
import { IMedication, IRequest, ITask } from '@/types';
import { cn } from '@/utils/utils';
import CallsPanel from './CallsPanel';

interface MedicationsProps {
  task: ITask;
  medication: IMedication;
  request: IRequest;
  index: number;
}

const Medication = ({ task, medication, index }: MedicationsProps) => {
  const taskModal = useModal('task-modal');
  const medicationId = taskModal.args?.medicationId;

  const [isOpen, setIsOpen] = useState(medicationId === medication.id ? true : index === 0);

  const handleSelected = () => {
    setIsOpen(!isOpen);
  };

  return (
    <li className="cursor-pointer">
      <div className="flex flex-row items-center justify-between gap-2 p-2 hover:bg-gray-100" onClick={handleSelected}>
        <div className="flex flex-row items-center gap-2 ">
          <MedIcon medication={medication} />
          <div className="flex flex-col gap-1 text-base text-gray-900 ">
            <div className="font-semibold">{medication.medication}</div>
            <div className="text-xs text-gray-500 truncate line-clamp-1">
              {medication.variant}{' '}
              {medication.label && (
                <>
                  for{' '}
                  <span className="text-gray-900 underline decoration-dotted decoration-purple-800">
                    {medication.label}
                  </span>
                </>
              )}
            </div>
          </div>
        </div>

        <div>{medication.dosage}</div>

        <div>
          <Pluralize singular={medication.dosage_form} count={medication.quantity} />
        </div>
        <div>{medication.preference}</div>
        <div>{medication.partial_stock_allowed && <Badge variant={'complete'}>Partial OK</Badge>}</div>
        <div>
          {medication.note && (
            <HoverCard>
              <HoverCardTrigger>
                <GoNote size={'20'} className="text-gray-500 cursor-pointer " />
              </HoverCardTrigger>
              <HoverCardContent>
                <div className="flex flex-row items-center gap-1">
                  <GoNote size={'20'} className="text-gray-500" />
                  <div className="text-xs font-semibold leading-6 text-gray-700">Medication Note</div>
                </div>

                <div className="mt-2 text-xs text-gray-500 whitespace-pre-line">{medication.note}</div>
              </HoverCardContent>
            </HoverCard>
          )}
        </div>
        <div>
          <Badge>{task.status}</Badge>
        </div>
        <div className="">
          {isOpen ? (
            <ChevronUp size={20} className="text-gray-500" />
          ) : (
            <ChevronDown size={20} className="text-gray-500 " />
          )}
        </div>
      </div>
      <div className={cn('bg-gray-100 p-2', isOpen ? 'block' : 'hidden')}>
        <CallsPanel task={task} />
      </div>
    </li>
  );
};

export default Medication;
