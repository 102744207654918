import { useParams } from 'react-router';
import { IMessage } from '@/types.ts';
import { getUrl } from '@/utils/api';

function useMessages() {
  const { room } = useParams();
  let url = 'v2/messages';

  if (room) {
    url += `?room=${room}`;
  }

  const query = getUrl(url, {
    refetchInterval: 10000,
  });
  return {
    ...query,
    data: query.data as IMessage[],
  };
}

export default useMessages;
