import { ITask } from '@/types.ts';
import { getUrl } from '@/utils/api';

function useManualCalls(options = {}) {
  const query = getUrl(`admin/manual_calls`, options);
  return {
    ...query,
    data: query.data as ITask[],
  };
}

export default useManualCalls;
