import { ITicket } from '@/types.ts';
import { getUrl } from '@/utils/api';

function useTickets(status: string, options = {}) {
  const query = getUrl(`admin/tickets?status=${status}`, options);
  return {
    ...query,
    data: query.data as ITicket[],
  };
}

export default useTickets;
