import { PlayCircle, Send } from 'lucide-react';
import { useQueryClient } from 'react-query';
import { Button } from '@/components/ui/button';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Textarea } from '@/components/ui/textarea';
import { Tooltip, TooltipTrigger, TooltipContent, TooltipProvider } from '@/components/ui/tooltip';
import useTask from '@/hooks/useTask';
import { ICall, IRequest } from '@/types';
import axiosClient from '@/utils/axiosClient';
import { cn } from '@/utils/utils';
import Transcript from './Transcript';

interface CallDispositionProps {
  taskId: string;
  requestId: string;
  call: ICall;
}

const CallDisposition = ({ call, taskId, requestId }: CallDispositionProps) => {
  const queryClient = useQueryClient();

  const task = useTask(taskId);

  const save = (data: any) => {
    axiosClient.put(`/admin/tasks/${taskId}`, data).then((response) => {
      queryClient.setQueryData([`admin/tasks/${taskId}`], response.data);

      const oldRequestData = queryClient.getQueryData<IRequest>([`admin/requests/${requestId}`]);
      queryClient.setQueryData([`admin/requests/${requestId}`], {
        ...oldRequestData,
        tasks: oldRequestData?.tasks.map((t) => (t.id === taskId ? response.data : t)),
      });
    });
  };

  const handleDisposition = (disposition: string) => {
    save({ stock_status: disposition, status: 'completed' });
  };

  const handleManualQueue = () => {
    save({ stock_status: 'callback', status: 'manual_queue' });
  };

  if (!task?.data?.id) {
    return null;
  }

  return (
    <div>
      <Tabs defaultValue="transcript" className="w-full">
        <div className="flex items-center justify-between">
          <TabsList>
            <TabsTrigger value="transcript">Transcript</TabsTrigger>
            <TabsTrigger value="ai_summary_and_notes">Summary</TabsTrigger>
            <TabsTrigger value="internal_notes">Internal Notes</TabsTrigger>
            <TabsTrigger value="record">Record</TabsTrigger>
          </TabsList>
          <div className="flex items-center">
            {call.recording_url && (
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      variant="outline"
                      size="sm"
                      className="mr-2"
                      onClick={() => window.open(call.recording_url, '_blank')}
                    >
                      <PlayCircle className="w-4 h-4" />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>This will open the recording for this call in another window</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            )}
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button variant="outline" size="sm" onClick={handleManualQueue}>
                    <Send className="w-4 h-4" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  <p>This will send the call to the Manual Queue, and change the stock status to 'Follow up'</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        </div>
        <TabsContent value="transcript">
          <div className="flex flex-col gap-2">
            <div className="">
              <Textarea
                className="w-full p-2 text-sm bg-green-100 border border-gray-300 rounded-lg"
                placeholder="Customer Notes"
                defaultValue={task.data.notes}
                onBlur={(e) => save({ notes: e.target.value })}
              />
            </div>

            <div className="flex gap-2 ">
              <Button
                className={cn(
                  'w-full text-white bg-green-500 rounded-lg',
                  task.data.stock_status === 'in_stock' && 'bg-primary',
                )}
                onClick={() => handleDisposition('in_stock')}
              >
                In Stock
              </Button>
              <Button
                className={cn(
                  'w-full text-white bg-red-500 rounded-lg',
                  task.data.stock_status === 'out_of_stock' && 'bg-primary',
                )}
                onClick={() => handleDisposition('out_of_stock')}
              >
                Out of Stock
              </Button>
              <Button
                className={cn(
                  'w-full text-white bg-yellow-500 rounded-lg',
                  task.data.stock_status === 'invalid' && 'bg-primary',
                )}
                onClick={async () => {
                  try {
                    await handleDisposition('invalid');

                    await axiosClient.patch(`/admin/pharmacies/${task.data.pharmacy_id}/update_invalid_status`, {
                      invalid_pharmacy: true,
                    });
                  } catch (error) {
                    console.error('Error marking pharmacy as invalid:', error);
                  }
                }}
              >
                Invalid
              </Button>
            </div>

            <Transcript call={call} />
          </div>
        </TabsContent>
        <TabsContent value="ai_summary_and_notes">{call.summary}</TabsContent>
        <TabsContent value="internal_notes">
          <div className="">
            <Textarea
              className="w-full p-2 text-sm border border-gray-300 rounded-lg"
              placeholder="Internal Notes"
              defaultValue={task.data.internal_notes}
              onBlur={(e) => save({ internal_notes: e.target.value })}
            />
          </div>
        </TabsContent>
        <TabsContent value="record">
          <pre>{JSON.stringify(task?.data, null, 2)}</pre>
          <pre>{JSON.stringify(call, null, 2)}</pre>
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default CallDisposition;
