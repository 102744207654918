import { Link } from 'react-router-dom';
import Loading from '@/components/Loading';
import { StatusIndicator } from '@/components/Requests/StatusIndicator';
import Time from '@/components/Time';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import useCallHistory from '@/hooks/useCallHistory';
import { IPharmacy } from '@/types';

const PharmacyCallHistory = ({ pharmacy }: { pharmacy: IPharmacy }) => {
  const callHistory = useCallHistory(pharmacy.id);

  return (
    <Table className="text-xs">
      <TableHeader>
        <TableRow>
          <TableHead>Status</TableHead>
          <TableHead>Date</TableHead>
          <TableHead>Medication</TableHead>
          <TableHead>Variant</TableHead>
          <TableHead>Dosage</TableHead>
          <TableHead>Preference</TableHead>
          <TableHead>Quantity</TableHead>
          <TableHead>Request</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {callHistory?.data?.map((call) => (
          <TableRow key={call.id}>
            <TableCell>
              <StatusIndicator status={call.task.stock_status} />
            </TableCell>
            <TableCell>
              <Time>{call.created_at}</Time>
            </TableCell>
            <TableCell>{call.medication.medication}</TableCell>
            <TableCell>{call.medication.variant}</TableCell>
            <TableCell>
              {call.medication.dosage} {call.medication.dosage_form}
            </TableCell>
            <TableCell>{call.medication.preference}</TableCell>
            <TableCell>{call.medication.quantity}</TableCell>
            <TableCell>
              <Link to={`/admin/requests/${call.task.request_id}`} className="text-blue-600 hover:underline">
                View Request
              </Link>
            </TableCell>
          </TableRow>
        ))}
        {callHistory?.isLoading && (
          <TableRow>
            <TableCell colSpan={8} className="text-center">
              <Loading />
            </TableCell>
          </TableRow>
        )}
        {callHistory?.data?.length === 0 && (
          <TableRow>
            <TableCell colSpan={8} className="text-center">
              No call history for this pharmacy
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default PharmacyCallHistory;
