import React, { createContext, useState, useContext, useEffect, useCallback, ReactNode } from 'react';
import { Device, Call } from '@twilio/voice-sdk';
import { toast } from 'react-toastify';
import axiosClient from '@/utils/axiosClient';

interface CallContextType {
  device: Device | null;
  activeCall: Call | null;
  isMuted: boolean;
  callDuration: number;
  initializeDevice: () => Promise<void>;
  setActiveCall: (call: Call | null) => void;
  setIsMuted: (muted: boolean) => void;
  endCall: () => void;
  startCall: () => void;
}

const CallContext = createContext<CallContextType | undefined>(undefined);

interface CallProviderProps {
  children: ReactNode;
  shouldInitialize: boolean;
}

export const CallProvider: React.FC<CallProviderProps> = ({ children, shouldInitialize }) => {
  const [device, setDevice] = useState<Device | null>(null);
  const [activeCall, setActiveCall] = useState<Call | null>(null);
  const [isMuted, setIsMuted] = useState<boolean>(false);
  const [callDuration, setCallDuration] = useState<number>(0);

  const initializeDevice = useCallback(async () => {
    if (!device) {
      try {
        const response = await axiosClient.get<{ token: string }>('/twilio/token');
        const newDevice = new Device(response.data.token, {
          codecPreferences: ['opus', 'pcmu'] as any,
          allowIncomingWhileBusy: true,
        });
        newDevice.on('ready', () => console.log('Twilio Device is ready'));
        newDevice.on('error', (error) => console.error('Twilio Device error:', error));
        setDevice(newDevice);
      } catch (error) {
        console.error('Failed to initialize Twilio device:', error);
        toast.error('Failed to initialize the calling system. Please try again.', {
          autoClose: 2000,
        });
      }
    }
  }, [device]);

  const startCall = useCallback(() => {
    setCallDuration(0);
  }, []);

  const endCall = useCallback(() => {
    if (activeCall) {
      activeCall.disconnect();
      setActiveCall(null);
      setIsMuted(false);
      setCallDuration(0);
    }
  }, [activeCall]);

  useEffect(() => {
    if (shouldInitialize) {
      initializeDevice();
    }
    return () => {
      if (device) {
        device.destroy();
      }
    };
  }, [initializeDevice, device, shouldInitialize]);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (activeCall) {
      interval = setInterval(() => {
        setCallDuration((prev) => prev + 1);
      }, 1000);
    } else {
      setCallDuration(0);
    }
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [activeCall]);

  useEffect(() => {
    if (activeCall) {
      const handleDisconnect = () => {
        setActiveCall(null);
        setIsMuted(false);
        setCallDuration(0);
      };
      activeCall.on('disconnect', handleDisconnect);
      return () => {
        activeCall.off('disconnect', handleDisconnect);
      };
    }
  }, [activeCall]);

  const value = {
    device,
    activeCall,
    isMuted,
    callDuration,
    initializeDevice,
    setActiveCall,
    setIsMuted,
    endCall,
    startCall,
  };

  return <CallContext.Provider value={value}>{children}</CallContext.Provider>;
};

export const useCallContext = () => {
  const context = useContext(CallContext);
  if (context === undefined) {
    throw new Error('useCallContext must be used within a CallProvider');
  }
  return context;
};

export default CallProvider;
