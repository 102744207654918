import { useState } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Link } from 'react-router-dom';
import { Badge } from '@/components/ui/badge';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import useRequest from '@/hooks/useRequest';
import useTask from '@/hooks/useTask';
import Drawer from '../../Modals/Drawer';
import Map from './Map';
import Medications from './Medications';
import Pharmacy from './Pharmacy';
import PharmacyCallHistory from './PharmacyCallHistory';
import PharmacyCard from './PharmacyCard';

interface TaskModalProps {
  taskId: string;
  requestId: string;
  tab?: string;
}

const TaskDrawer = NiceModal.create(({ requestId, taskId }: TaskModalProps) => {
  const modal = useModal('task-modal');
  const { data: task, isLoading: isLoading } = useTask(taskId as string);
  const request = useRequest(requestId, true);

  const [tab, setTab] = useState<string | undefined>('tasks');

  if (isLoading || !task?.id) {
    return null;
  }

  const note = request?.data?.note;
  return (
    <Drawer modal={modal}>
      <div className="p-4 mr-1">
        <div className="flex flex-col w-full gap-6">
          <div className="flex justify-between gap-8">
            <div className="flex flex-row items-center flex-1 gap-2">
              <div className="flex flex-col justify-start ">
                <div className="text-xl font-semibold text-gray-600">{request?.data?.user?.email}</div>
                <div className="text-lg text-gray-600">{request?.data?.user?.name}</div>
                <div className="text-sm text-gray-600">{request?.data?.user?.id}</div>
                <div className="text-sm text-gray-600"></div>
              </div>
            </div>
            <div className="flex flex-col justify-end flex-1 gap-3 ">
              <div className="flex items-center justify-end gap-1"></div>
              <div className="flex flex-col items-end justify-end w-full gap-2">
                <Link to={`/admin/users/${request?.data?.user?.id}`}>
                  <Badge variant="pending">Go To User</Badge>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <hr className="mt-2 mb-3" />

        <Pharmacy task={task} />

        {note && (
          <div className="p-2 my-6 bg-blue-100 rounded-xl ">
            <div className="font-semibold">Notes:</div>
            <div className="whitespace-pre-wrap">{note}</div>
          </div>
        )}

        <Tabs defaultValue={tab} className="w-full" onValueChange={setTab}>
          <TabsList>
            <TabsTrigger value="tasks">Tasks</TabsTrigger>
            <TabsTrigger value="call_history">Call History</TabsTrigger>
            <TabsTrigger value="location">Google Map</TabsTrigger>
            <TabsTrigger value="pharmacy">Pharmacy</TabsTrigger>
          </TabsList>
          <ScrollArea className="h-[calc(100vh-350px)]">
            <TabsContent value="tasks">
              <Medications task={task} />
            </TabsContent>
            <TabsContent value="call_history">
              <PharmacyCallHistory pharmacy={task.pharmacy} />
            </TabsContent>
            <TabsContent value="location">
              <Map pharmacy={task.pharmacy} />
            </TabsContent>
            <TabsContent value="pharmacy">
              <PharmacyCard pharmacy={task.pharmacy} />
            </TabsContent>
          </ScrollArea>
        </Tabs>
      </div>
    </Drawer>
  );
});

export default TaskDrawer;
