import React, { useEffect } from 'react';
import { useCopyToClipboard } from '@uidotdev/usehooks';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { cn } from '@/utils/utils';
import { Button } from './ui/button';

interface ClickToCopyProps {
  text: string;
  children?: React.ReactNode;
  variant?:
    | 'default'
    | 'destructive'
    | 'outline'
    | 'secondary'
    | 'ghost'
    | 'link'
    | 'inStock'
    | 'outOfStock'
    | 'invalid'
    | null;
  className?: string;
}

const ClickToCopy = ({ text, className, children, variant = null }: ClickToCopyProps) => {
  const [copiedText, copyToClipboard] = useCopyToClipboard();
  const hasCopiedText = Boolean(copiedText);

  useEffect(() => {
    if (hasCopiedText) {
      const timer = setTimeout(() => {
        copyToClipboard('');
      }, 1500);
      return () => clearTimeout(timer);
    }
  }, [hasCopiedText]);

  const copy = children || text;

  return (
    <>
      <TooltipProvider>
        <Tooltip open={copiedText ? true : false}>
          <TooltipTrigger asChild>
            <Button
              variant={variant}
              className={cn('text-pretty text-left p-0 m-0 h-auto', className)}
              onClick={() => copyToClipboard(text)}
            >
              {copy}
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            <p>{hasCopiedText ? <>Copied!</> : <>Click to Copy</>}</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </>
  );
};

export default ClickToCopy;
