import { useState, useEffect, useRef } from 'react';
import { ArrowLeft } from 'lucide-react';
import { MdEmail } from 'react-icons/md';
import { useQueryClient } from 'react-query';
import { Link, useParams } from 'react-router-dom';
import useMe from '@/hooks/useMe';
import useMessages from '@/hooks/useMessages';
import axiosClient from '@/utils/axiosClient';
import { formatDateTime } from '@/utils/helpers';
import { cn } from '@/utils/utils';
import { Button } from '../ui/button';
import { Textarea } from '../ui/textarea';

const Chat = () => {
  const { room } = useParams();
  const currentUser = useMe();
  const messages = useMessages();
  const queryClient = useQueryClient();

  const [newMessage, setNewMessage] = useState('');
  const messagesEndRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages.data]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (newMessage.length === 0) return;

    axiosClient
      .post(`/v2/messages`, {
        body: newMessage,
        room: room,
      })
      .then((response) => {
        const newMessages = messages.data?.concat(response.data);

        queryClient.setQueryData(['v2/messages'], newMessages);
        queryClient.setQueryData([`v2/messages?room=${room}`], newMessages);
        setNewMessage('');
      });
  };

  return (
    <>
      <div className="flex items-center justify-start gap-1 mt-4 mb-6 sm:mt-2">
        <div className="mr-2 leading-snug text-gray-600 hover:text-violet-600">
          <Link to="/requests">
            <ArrowLeft />
          </Link>
        </div>
        <div className="font-medium leading-snug text-violet-600">Chat</div>
      </div>

      <div className="flex flex-col justify-between gap-4 mb-8 md:flex-row sm:gap-8">
        <div className="flex flex-col">
          <div className="text-3xl font-bold leading-8 text-neutral-800 sm:text-2xl text-pretty">
            Get the help you need with Needle!
          </div>
          <div className="max-w-sm text-sm font-normal leading-normal text-gray-500 md:max-w-none text-pretty">
            Direct message Needle with any questions or concerns you may have. We're here to help!
            <div className="mt-4 font-semibold">Messages are typically answered within 24 hours.</div>
          </div>
        </div>

        <div className="flex items-center justify-end">
          <Button variant={'ghost'} className="flex items-center gap-2 text-purple-600 rounded-full">
            <MdEmail className="w-6 h-6" />
            email@findneedle.co
          </Button>
        </div>
      </div>

      <div className="flex flex-col flex-1 gap-4 overflow-y-hidden">
        {currentUser.isLoading || messages.isLoading ? (
          <div>Loading...</div>
        ) : (
          <div
            className="flex flex-col flex-1 gap-8 overflow-y-auto"
            style={{ maxHeight: 'calc(100vh - 400px)', minHeight: 'calc(100vh - 400px)' }}
          >
            {messages.data?.map((message) => {
              const isNeedle = message.admin_message;

              return (
                <div
                  key={message.id}
                  className={cn(
                    `flex flex-col gap-2 p-4 rounded-lg border border-gray-300 max-w-7xl`,
                    isNeedle
                      ? 'self-start bg-brand-dark-blue rounded-tl-none text-white'
                      : 'self-end rounded-tr-none bg-white',
                  )}
                >
                  <div className="flex items-center justify-between h-full gap-8 ">
                    <div className={cn('font-semibold ', isNeedle ? 'text-white' : 'text-neutral-800')}>
                      {message.author}
                    </div>
                    <div className={cn('text-xs ', isNeedle ? 'text-white' : 'text-neutral-500')}>
                      {formatDateTime(message.created_at)}
                    </div>
                  </div>
                  <div className={cn('text-sm ', isNeedle ? 'text-white' : 'text-gray-600')}>{message.body}</div>
                </div>
              );
            })}
            <div ref={messagesEndRef} />
          </div>
        )}
      </div>

      <div className="mt-4">
        <form onSubmit={handleSubmit} className="flex flex-row items-center justify-start gap-2">
          <Textarea
            placeholder="Send Needle a message and we will get back to you within 1 business day..."
            className="flex-1 p-2 text-sm text-gray-600 border border-gray-300 rounded-md"
            onChange={(e) => setNewMessage(e.target.value)}
            value={newMessage}
          />
          <Button
            type="submit"
            className="px-4 py-2 text-sm text-white rounded-md bg-violet-600"
            disabled={newMessage.length === 0}
          >
            Send Message
          </Button>
        </form>
      </div>
    </>
  );
};

export default Chat;
