import { ITask } from '@/types.ts';
import { getUrl } from '@/utils/api';

function useTask(taskId: string) {
  const query = getUrl(`admin/tasks/${taskId}`, {
    refetchInterval: 10000,
  });
  return {
    ...query,
    data: query.data as ITask,
  };
}

export default useTask;
