import { FireIcon } from '@heroicons/react/24/solid';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';

interface ExpeditedIndicatorProps {
  processingTime: string | undefined;
}

export function ExpeditedIndicator({ processingTime }: ExpeditedIndicatorProps) {
  if (processingTime !== 'Same Day Expedited' && processingTime !== 'Weekend Expedited') {
    return null;
  }

  const isWeekendExpedited = processingTime === 'Weekend Expedited';

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <span
            className={`inline-flex items-center px-2 py-1 ml-2 text-xs font-medium rounded-full whitespace-nowrap ${
              isWeekendExpedited ? 'text-purple-700 bg-purple-100' : 'text-orange-700 bg-orange-100'
            }`}
          >
            <FireIcon className="w-3 h-3 mr-1 flex-shrink-0" />
            <span className="flex-shrink-0">{isWeekendExpedited ? 'Weekend' : 'Same Day'}</span>
          </span>
        </TooltipTrigger>
        <TooltipContent>This request is expedited for {processingTime} processing</TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}
